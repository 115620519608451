import React, { useState } from 'react';
import { Button, Flex, View, Text } from '@instructure/ui';
import { useResponsiveContext } from 'hooks/useResponsiveContext';
import AccountCreation from 'containers/PreApplication/SignUp/IUSignUp/components/AccountCreation';
import roles from '../../roles';
import Breadcrumbs from '../../../../../../components/IUBreadcrumbs/IUBreadcrumb';
import IURoleRender from '../../../IURoleRender';
import { RoleSelectorProps } from '../../type';

const IURoleSelector: React.FC<RoleSelectorProps> = ({
  user,
  onChange,
  onChangeRole,
  disabled = false,
  errors = {},
  buttonText = 'Submit',
  showEmail = false,
  showPassword = false,
}) => {
  const [nextStepLabel, setNextStepLabel] = useState('Submit');
  const [nextStepAllowed, setNextStepAllowed] = useState(true);
  const [selectedRole, setSelectedRole] = useState<string | null>(user?.userType || null);

  const currentRole = roles.find(role => role.id === selectedRole);
  const { isMobile } = useResponsiveContext();

  if (!selectedRole) {
    return (
      <>
        <Flex.Item data-node="bhee" shouldShrink shouldGrow>
          <View as="div" minHeight="32em">
            <View as="div" margin="0 0">
              <Text weight="bold" size="x-large">
                Select Role
              </Text>
            </View>
            <View as="div" margin="large 0 large 0">
              <Text>Please select the role that best describes your responsibilities at your organization.</Text>
            </View>
            <IURoleRender
              selectedRole={selectedRole}
              onChangeRole={(role: string) => {
                setSelectedRole(role);
                onChangeRole(role);
                return role;
              }}
              disabled={disabled}
            />
          </View>
        </Flex.Item>
        <Flex.Item>
          <Flex as="div" margin="none" direction="row">
            <Flex.Item shouldShrink shouldGrow>
              {/* No Cancel Button */}
            </Flex.Item>
            <Flex.Item>
              <Button
                type="submit"
                interaction={!nextStepAllowed ? 'disabled' : 'enabled'}
                color="primary"
                margin="xx-small"
                size="medium"
              >
                {nextStepLabel}
              </Button>
            </Flex.Item>
          </Flex>
        </Flex.Item>
      </>
    );
  }

  return (
    <>
      <Flex.Item shouldShrink shouldGrow>
        <View as="div" margin="none" minHeight="32em">
          {!disabled && (
            <Breadcrumbs
              items={[
                {
                  label: 'Select Role',
                  onClick: () => setSelectedRole(null),
                },
                {
                  label: isMobile ? `Registration` : `${currentRole?.title} Registration`,
                  to: '',
                },
              ]}
            />
          )}
          <AccountCreation
            selectedRole={selectedRole}
            setIsNextStepAllowed={setNextStepAllowed}
            setNextStepLabel={setNextStepLabel}
            user={user}
            onChange={onChange}
            errors={errors}
            showPassword={showPassword}
            showEmail={showEmail}
          />
        </View>
      </Flex.Item>
      <Flex.Item>
        <Flex as="div" margin="none" direction="row">
          <Flex.Item shouldShrink shouldGrow>
            {/* No Cancel Button */}
          </Flex.Item>
          <Flex.Item>
            <>
              <Button
                interaction={!nextStepAllowed ? 'disabled' : 'enabled'}
                color="primary"
                margin="xx-small"
                size="medium"
                type="submit"
              >
                {buttonText}
              </Button>
            </>
          </Flex.Item>
        </Flex>
      </Flex.Item>
    </>
  );
};

export default IURoleSelector;
