import React from 'react';
import { Button, Text, Flex, View } from '@instructure/ui';
import * as Routes from 'Routes';
import { authenticityToken } from 'utils/FetchHelpers';
import Icon from 'components/Icon';

const IUSocialAuthButtons: React.FC = () => {
  return (
    <>
      <View as="hr" borderWidth="small 0 small 0" width="100%" margin="x-large 0 x-large 0" />
      <View margin="x-small none x-small none">
        <form action={Routes.users_auth_passthru_google_oauth2_path({ mode: 'sign_up_oauth' })} method="post">
          <Flex as="div">
            <input type="hidden" name="authenticity_token" value={authenticityToken()} />
            <Button display="block" type="submit">
              <Flex as="div" justifyItems="start" alignItems="start" data-testid="margin-container">
                <Flex.Item>
                  <Icon icon="google" />
                </Flex.Item>
                <Flex.Item shouldShrink shouldGrow>
                  <Text>Log in with Google</Text>
                </Flex.Item>
              </Flex>
            </Button>
          </Flex>
        </form>
      </View>
    </>
  );
};

export default IUSocialAuthButtons;
