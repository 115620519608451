import React from 'react';
import { Helmet } from 'react-helmet';
import { isEmpty, join } from 'ramda';
import { FormFieldGroup, Text, View, Button } from '@instructure/ui';
import * as Routes from 'Routes';
import { authenticityToken } from 'utils/FetchHelpers';

import Flash from 'components/IUFlash';
import TextField from 'components/IUTextField';
import { useResponsiveContext } from 'hooks/useResponsiveContext';
import IUPanelFrame from 'containers/PreApplication/sharedComponents/IUPanelFrame';

interface IUResendUnlockProps {
  errors: string[];
}

const IUResendUnlock = ({ errors }: IUResendUnlockProps) => {
  const { isMobile, isDesktop } = useResponsiveContext();
  const padding = isMobile || isDesktop ? '13em' : undefined;
  return (
    <>
      <Helmet>
        <title>Resend Unlock Instructions</title>
      </Helmet>
      <form action={Routes.user_unlock_path()} method="post">
        <input type="hidden" name="authenticity_token" value={authenticityToken()} />
        {!isEmpty(errors) && <Flash flash={['alert', join(', ', errors)]} />}

        <IUPanelFrame
          padding={padding}
          cancelButton={
            <View data-node="button-wrapper" as="div" padding="xx-small">
              <Button href={Routes.new_user_session_path()}>Back to Login</Button>
            </View>
          }
          nextButton={
            <View data-node="button-wrapper" as="div" padding="xx-small">
              <Button data-node="resend_unlock_button" color="primary" type="submit">
                Resend Instructions
              </Button>
            </View>
          }
        >
          <View as="div" margin="none">
            <Text weight="bold" size="x-large">
              Resend unlock instructions
            </Text>
            <View padding="large none none none" as="div">
              {!isEmpty(errors) && <Flash flash={['alert', join(', ', errors)]} />}
            </View>
          </View>
          <FormFieldGroup description="">
            <TextField
              data-node="email"
              name="user[email]"
              id="user_email"
              label="Email"
              placeholder="Enter email address"
              fullWidth
              autoComplete="email"
              variant="filled"
            />
          </FormFieldGroup>
        </IUPanelFrame>
      </form>
    </>
  );
};

export default IUResendUnlock;
