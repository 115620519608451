import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Flex, View, Text, Button } from '@instructure/ui';
import { useResponsiveContext } from 'hooks/useResponsiveContext';
import ConfirmationsRepository from 'repositories/ConfirmationsRepository';
import * as Routes from 'Routes';
import { authenticityToken } from 'utils/FetchHelpers';
import IUModal from 'components/IUModal';

interface IUEmailConfirmationProps {
  email: string;
  isPartner?: boolean;
}

const IUEmailConfirmation = ({ email, isPartner }: IUEmailConfirmationProps) => {
  const [showModal, updateShowModal] = useState(false);
  const { isMobile, isTablet } = useResponsiveContext();
  const padding = isMobile ? '10em' : '13em';
  const height = isTablet && !isMobile ? `100%` : `calc(100vh - ${padding})`;

  return (
    <>
      <Helmet>
        <title>Check your email</title>
      </Helmet>
      <input type="hidden" name="authenticity_token" value={authenticityToken()} />
      <Flex data-node="parent" as="div" direction="column" justifyItems="center" height={height} width="100%">
        <Flex.Item shouldShrink shouldGrow>
          <View as="div" margin="0 0">
            <Text weight="bold" size="x-large">
              Check your email
            </Text>
          </View>
          <View as="div" margin="large none none none">
            We sent a confirmation email to:
          </View>
          <View as="div" margin="medium none none none">
            {email}
          </View>
          <View as="div" margin="medium none none none">
            Check your email and click on the confirmation link to continue.
          </View>
          <View as="div" margin="medium none none none">
            Be sure to check your spam folder if you do not receive an email in the next 5 minutes!
          </View>
        </Flex.Item>
        <Flex.Item shouldShrink shouldGrow>
          <></>
        </Flex.Item>
        <Flex.Item>
          <Flex as="div" margin="none" direction="row">
            <Flex.Item shouldShrink shouldGrow>
              <Button
                onClick={() => {
                  ConfirmationsRepository.create({ email }).then(() => {
                    updateShowModal(true);
                  });
                }}
                color="secondary"
                margin="none none none xx-small"
                size="medium"
              >
                Resend Email
              </Button>
            </Flex.Item>
            <Flex.Item>
              <>
                <Button
                  href={isPartner ? Routes.new_partner_session_path() : Routes.new_user_session_path()}
                  interaction="enabled"
                  color="primary"
                  margin="xx-small"
                  size="medium"
                  type="submit"
                >
                  Sign In
                </Button>
              </>
            </Flex.Item>
          </Flex>
        </Flex.Item>
      </Flex>
      {showModal && (
        <IUModal
          onClose={() => {
            updateShowModal(false);
          }}
          title="Check your email"
          label="Please check your email, we have resent the email."
          open={showModal}
          buttonAlign="center"
          data-node="resend-email-modal"
        >
          <View as="div" maxWidth="45em">
            An email has been re-sent to <Text weight="bold">{email}</Text>. Please make sure to check your spam folder
            if you do not receive an email in the next 5 minutes.
          </View>
        </IUModal>
      )}
    </>
  );
};

export default IUEmailConfirmation;
