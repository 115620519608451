import {
  IconCalendarDaysSolid,
  IconCalendarDaysLine,
  IconCalendarDaySolid,
  IconCalendarDayLine,
  IconCalendarClockSolid,
  IconCalendarClockLine,
  IconCalendarAddSolid,
  IconCalendarAddLine,
  IconCalculatorSolid,
  IconCalculatorLine,
  IconCalculatorDesmosSolid,
  IconCalculatorDesmosLine,
  IconButtonAndIconMakerSolid,
  IconButtonAndIconMakerLine,
  IconBulletListSolid,
  IconBulletListLine,
  IconBulletListSquareSolid,
  IconBulletListSquareLine,
  IconBulletListRomanSolid,
  IconBulletListRomanLine,
  IconBulletListCircleOutlineSolid,
  IconBulletListCircleOutlineLine,
  IconBulletListAlphaSolid,
  IconBulletListAlphaLine,
  IconBoxSolid,
  IconBoxLine,
  IconBookmarkSolid,
  IconBookmarkLine,
  IconBoldSolid,
  IconBoldLine,
  IconBlueprintSolid,
  IconBlueprintLine,
  IconBlueprintLockSolid,
  IconBlueprintLockLine,
  IconBankSolid,
  IconBankLine,
  IconAudioSolid,
  IconAudioLine,
  IconAudioOffSolid,
  IconAudioOffLine,
  IconAttachMediaSolid,
  IconAttachMediaLine,
  IconAssignmentSolid,
  IconAssignmentLine,
  IconArrowUpSolid,
  IconArrowUpLine,
  IconArrowStartSolid,
  IconArrowStartLine,
  IconArrowRightSolid,
  IconArrowRightLine,
  IconArrowOpenUpSolid,
  IconArrowOpenUpLine,
  IconArrowOpenStartSolid,
  IconArrowOpenStartLine,
  IconArrowOpenRightSolid,
  IconArrowOpenRightLine,
  IconArrowOpenLeftSolid,
  IconArrowOpenLeftLine,
  IconArrowOpenEndSolid,
  IconArrowOpenEndLine,
  IconArrowOpenDownSolid,
  IconArrowOpenDownLine,
  IconArrowNestSolid,
  IconArrowNestLine,
  IconArrowLeftSolid,
  IconArrowLeftLine,
  IconArrowEndSolid,
  IconArrowEndLine,
  IconArrowDownSolid,
  IconArrowDownLine,
  IconArchiveSolid,
  IconArchiveLine,
  IconArcSolid,
  IconArcLine,
  IconAppleSolid,
  IconAppleLine,
  IconAnnouncementSolid,
  IconAnnouncementLine,
  IconAnnotateSolid,
  IconAnnotateLine,
  IconAndroidSolid,
  IconAndroidLine,
  IconAnalyticsSolid,
  IconAnalyticsLine,
  IconAlertsSolid,
  IconAlertsLine,
  IconAdminSolid,
  IconAdminLine,
  IconAdminToolsSolid,
  IconAdminToolsLine,
  IconAddressBookSolid,
  IconAddressBookLine,
  IconAddSolid,
  IconAddLine,
  IconAddMediaSolid,
  IconAddMediaLine,
  IconAddFolderSolid,
  IconAddFolderLine,
  IconA11ySolid,
  IconA11yLine,
  IconDropDownSolid,
  IconDropDownLine,
  IconDragHandleSolid,
  IconDragHandleLine,
  IconDownloadSolid,
  IconDownloadLine,
  IconDocumentSolid,
  IconDocumentLine,
  IconDiscussionSolid,
  IconDiscussionLine,
  IconDiscussionXSolid,
  IconDiscussionXLine,
  IconDiscussionSearchSolid,
  IconDiscussionSearchLine,
  IconDiscussionReplySolid,
  IconDiscussionReplyLine,
  IconDiscussionReplyDarkSolid,
  IconDiscussionReplyDarkLine,
  IconDiscussionReply2Solid,
  IconDiscussionReply2Line,
  IconDiscussionNewSolid,
  IconDiscussionNewLine,
  IconDiscussionCheckSolid,
  IconDiscussionCheckLine,
  IconDeactivateUserSolid,
  IconDeactivateUserLine,
  IconDashboardSolid,
  IconDashboardLine,
  IconCropSolid,
  IconCropLine,
  IconCoursesSolid,
  IconCoursesLine,
  IconCopySolid,
  IconCopyLine,
  IconCopyCourseSolid,
  IconCopyCourseLine,
  IconConfigureSolid,
  IconConfigureLine,
  IconComposeSolid,
  IconComposeLine,
  IconCompleteSolid,
  IconCompleteLine,
  IconCompassSolid,
  IconCompassLine,
  IconCommonsSolid,
  IconCommonsLine,
  IconCommentsOnSolid,
  IconCommentsOnLine,
  IconCommentsOffSolid,
  IconCommentsOffLine,
  IconCommentSolid,
  IconCommentLine,
  IconCollectionSolid,
  IconCollectionLine,
  IconCollectionSaveSolid,
  IconCollectionSaveLine,
  IconCollapseSolid,
  IconCollapseLine,
  IconCodeSolid,
  IconCodeLine,
  IconCloudUploadSolid,
  IconCloudUploadLine,
  IconCloudLockSolid,
  IconCloudLockLine,
  IconCloudDownloadSolid,
  IconCloudDownloadLine,
  IconClosedCaptioningSolid,
  IconClosedCaptioningLine,
  IconClosedCaptioningOnSolid,
  IconClosedCaptioningOnLine,
  IconClosedCaptioningOffSolid,
  IconClosedCaptioningOffLine,
  IconClockSolid,
  IconClockLine,
  IconClearTextFormattingSolid,
  IconClearTextFormattingLine,
  IconCircleArrowUpSolid,
  IconCircleArrowUpLine,
  IconCircleArrowDownSolid,
  IconCircleArrowDownLine,
  IconCheckSolid,
  IconCheckLine,
  IconCheckPlusSolid,
  IconCheckPlusLine,
  IconCheckMarkSolid,
  IconCheckMarkLine,
  IconCheckMarkIndeterminateSolid,
  IconCheckMarkIndeterminateLine,
  IconCheckDarkSolid,
  IconCheckDarkLine,
  IconChatSolid,
  IconChatLine,
  IconCertifiedSolid,
  IconCertifiedLine,
  IconCanvasLogoSolid,
  IconCanvasLogoLine,
  IconCalendarReservedSolid,
  IconCalendarReservedLine,
  IconCalendarMonthSolid,
  IconCalendarMonthLine,
  IconHourGlassSolid,
  IconHourGlassLine,
  IconHomeSolid,
  IconHomeLine,
  IconHighlighterSolid,
  IconHighlighterLine,
  IconHeartSolid,
  IconHeartLine,
  IconHeaderSolid,
  IconHeaderLine,
  IconHamburgerSolid,
  IconHamburgerLine,
  IconGroupSolid,
  IconGroupLine,
  IconGroupNewSolid,
  IconGroupNewLine,
  IconGroupDarkNewSolid,
  IconGroupDarkNewLine,
  IconGradebookSolid,
  IconGradebookLine,
  IconGradebookImportSolid,
  IconGradebookImportLine,
  IconGradebookExportSolid,
  IconGradebookExportLine,
  IconGithubSolid,
  IconGithubLine,
  IconFullScreenSolid,
  IconFullScreenLine,
  IconForwardSolid,
  IconForwardLine,
  IconFolderSolid,
  IconFolderLine,
  IconFolderLockedSolid,
  IconFolderLockedLine,
  IconFlagSolid,
  IconFlagLine,
  IconFilterSolid,
  IconFilterLine,
  IconFilmstripSolid,
  IconFilmstripLine,
  IconFilesPublicDomainSolid,
  IconFilesPublicDomainLine,
  IconFilesObtainedPermissionSolid,
  IconFilesObtainedPermissionLine,
  IconFilesFairUseSolid,
  IconFilesFairUseLine,
  IconFilesCreativeCommonsSolid,
  IconFilesCreativeCommonsLine,
  IconFilesCopyrightSolid,
  IconFilesCopyrightLine,
  IconFileLockedSolid,
  IconFileLockedLine,
  IconFeedbackSolid,
  IconFeedbackLine,
  IconFastForwardSolid,
  IconFastForwardLine,
  IconFacebookSolid,
  IconFacebookLine,
  IconFacebookBoxedSolid,
  IconFacebookBoxedLine,
  IconEyeSolid,
  IconEyeLine,
  IconExternalLinkSolid,
  IconExternalLinkLine,
  IconExportSolid,
  IconExportLine,
  IconExportContentSolid,
  IconExportContentLine,
  IconExpandSolid,
  IconExpandLine,
  IconExpandStartSolid,
  IconExpandStartLine,
  IconExpandLeftSolid,
  IconExpandLeftLine,
  IconExpandItemsSolid,
  IconExpandItemsLine,
  IconExitFullScreenSolid,
  IconExitFullScreenLine,
  IconEssaySolid,
  IconEssayLine,
  IconEquellaSolid,
  IconEquellaLine,
  IconEquationSolid,
  IconEquationLine,
  IconEportfolioSolid,
  IconEportfolioLine,
  IconEndSolid,
  IconEndLine,
  IconEmptySolid,
  IconEmptyLine,
  IconEmailSolid,
  IconEmailLine,
  IconElevateLogoSolid,
  IconElevateLogoLine,
  IconEducatorsSolid,
  IconEducatorsLine,
  IconEditSolid,
  IconEditLine,
  IconDuplicateSolid,
  IconDuplicateLine,
  IconMoveDownSolid,
  IconMoveDownLine,
  IconMoveDownBottomSolid,
  IconMoveDownBottomLine,
  IconMoreSolid,
  IconMoreLine,
  IconModuleSolid,
  IconModuleLine,
  IconMinimizeSolid,
  IconMinimizeLine,
  IconMiniArrowUpSolid,
  IconMiniArrowUpLine,
  IconMiniArrowStartSolid,
  IconMiniArrowStartLine,
  IconMiniArrowRightSolid,
  IconMiniArrowRightLine,
  IconMiniArrowLeftSolid,
  IconMiniArrowLeftLine,
  IconMiniArrowEndSolid,
  IconMiniArrowEndLine,
  IconMiniArrowDownSolid,
  IconMiniArrowDownLine,
  IconMiniArrowDoubleSolid,
  IconMiniArrowDoubleLine,
  IconMicSolid,
  IconMicLine,
  IconMicOffSolid,
  IconMicOffLine,
  IconMessageSolid,
  IconMessageLine,
  IconMediaSolid,
  IconMediaLine,
  IconMatureSolid,
  IconMatureLine,
  IconMatureLightSolid,
  IconMatureLightLine,
  IconMaterialsRequiredSolid,
  IconMaterialsRequiredLine,
  IconMaterialsRequiredLightSolid,
  IconMaterialsRequiredLightLine,
  IconMasteryPathsSolid,
  IconMasteryPathsLine,
  IconMasteryLogoSolid,
  IconMasteryLogoLine,
  IconMasqueradeSolid,
  IconMasqueradeLine,
  IconMarkerSolid,
  IconMarkerLine,
  IconMarkAsReadSolid,
  IconMarkAsReadLine,
  IconLtiSolid,
  IconLtiLine,
  IconLockSolid,
  IconLockLine,
  IconLinkedinSolid,
  IconLinkedinLine,
  IconLinkSolid,
  IconLinkLine,
  IconLineReaderSolid,
  IconLineReaderLine,
  IconLikeSolid,
  IconLikeLine,
  IconLifePreserverSolid,
  IconLifePreserverLine,
  IconLaunchSolid,
  IconLaunchLine,
  IconKeyboardShortcutsSolid,
  IconKeyboardShortcutsLine,
  IconItalicSolid,
  IconItalicLine,
  IconInvitationSolid,
  IconInvitationLine,
  IconIntegrationsSolid,
  IconIntegrationsLine,
  IconInstructureSolid,
  IconInstructureLine,
  IconInstructureLogoSolid,
  IconInstructureLogoLine,
  IconInfoSolid,
  IconInfoLine,
  IconInfoBorderlessSolid,
  IconInfoBorderlessLine,
  IconIndentSolid,
  IconIndentLine,
  IconIndent2Solid,
  IconIndent2Line,
  IconInboxSolid,
  IconInboxLine,
  IconImportantDatesSolid,
  IconImportantDatesLine,
  IconImportSolid,
  IconImportLine,
  IconImportContentSolid,
  IconImportContentLine,
  IconImpactLogoSolid,
  IconImpactLogoLine,
  IconImmersiveReaderSolid,
  IconImmersiveReaderLine,
  IconImageSolid,
  IconImageLine,
  IconQuizStatsHighSolid,
  IconQuizStatsHighLine,
  IconQuizStatsDeviationSolid,
  IconQuizStatsDeviationLine,
  IconQuizStatsCronbachsAlphaSolid,
  IconQuizStatsCronbachsAlphaLine,
  IconQuizStatsAvgSolid,
  IconQuizStatsAvgLine,
  IconQuizInstructionsSolid,
  IconQuizInstructionsLine,
  IconQuestionSolid,
  IconQuestionLine,
  IconPublishSolid,
  IconPublishLine,
  IconProtractorSolid,
  IconProtractorLine,
  IconProgressSolid,
  IconProgressLine,
  IconPrinterSolid,
  IconPrinterLine,
  IconPrerequisiteSolid,
  IconPrerequisiteLine,
  IconPostToSisSolid,
  IconPostToSisLine,
  IconPlusSolid,
  IconPlusLine,
  IconPlaySolid,
  IconPlayLine,
  IconPinterestSolid,
  IconPinterestLine,
  IconPinSolid,
  IconPinLine,
  IconPermissionsSolid,
  IconPermissionsLine,
  IconPeerReviewSolid,
  IconPeerReviewLine,
  IconPeerGradedSolid,
  IconPeerGradedLine,
  IconPdfSolid,
  IconPdfLine,
  IconPauseSolid,
  IconPauseLine,
  IconPartialSolid,
  IconPartialLine,
  IconPaperclipSolid,
  IconPaperclipLine,
  IconPaintSolid,
  IconPaintLine,
  IconPageUpSolid,
  IconPageUpLine,
  IconPageDownSolid,
  IconPageDownLine,
  IconOvalHalfSolid,
  IconOvalHalfLine,
  IconOutdent2Solid,
  IconOutdent2Line,
  IconOutdentSolid,
  IconOutdentLine,
  IconOutcomesSolid,
  IconOutcomesLine,
  IconOpenFolderSolid,
  IconOpenFolderLine,
  IconOffSolid,
  IconOffLine,
  IconNumberedListSolid,
  IconNumberedListLine,
  IconNotepadSolid,
  IconNotepadLine,
  IconNoteSolid,
  IconNoteLine,
  IconNoteLightSolid,
  IconNoteLightLine,
  IconNoteDarkSolid,
  IconNoteDarkLine,
  IconNotGradedSolid,
  IconNotGradedLine,
  IconNoSolid,
  IconNoLine,
  IconNextUnreadSolid,
  IconNextUnreadLine,
  IconMutedSolid,
  IconMutedLine,
  IconMsWordSolid,
  IconMsWordLine,
  IconMsPptSolid,
  IconMsPptLine,
  IconMsExcelSolid,
  IconMsExcelLine,
  IconMoveUpSolid,
  IconMoveUpLine,
  IconMoveUpTopSolid,
  IconMoveUpTopLine,
  IconMoveStartSolid,
  IconMoveStartLine,
  IconMoveRightSolid,
  IconMoveRightLine,
  IconMoveLeftSolid,
  IconMoveLeftLine,
  IconMoveEndSolid,
  IconMoveEndLine,
  IconSyllabusSolid,
  IconSyllabusLine,
  IconSubtitlesSolid,
  IconSubtitlesLine,
  IconSubaccountsSolid,
  IconSubaccountsLine,
  IconStudioSolid,
  IconStudioLine,
  IconStudentViewSolid,
  IconStudentViewLine,
  IconStrikethroughSolid,
  IconStrikethroughLine,
  IconStopSolid,
  IconStopLine,
  IconStatsSolid,
  IconStatsLine,
  IconStarSolid,
  IconStarLine,
  IconStarLightSolid,
  IconStarLightLine,
  IconStandardsSolid,
  IconStandardsLine,
  IconSpeedGraderSolid,
  IconSpeedGraderLine,
  IconSortSolid,
  IconSortLine,
  IconSkypeSolid,
  IconSkypeLine,
  IconSisSyncedSolid,
  IconSisSyncedLine,
  IconSisNotSyncedSolid,
  IconSisNotSyncedLine,
  IconSisImportedSolid,
  IconSisImportedLine,
  IconShareSolid,
  IconShareLine,
  IconShapeRectangleSolid,
  IconShapeRectangleLine,
  IconShapePolygonSolid,
  IconShapePolygonLine,
  IconShapeOvalSolid,
  IconShapeOvalLine,
  IconSettingsSolid,
  IconSettingsLine,
  IconSettings2Solid,
  IconSettings2Line,
  IconSearchSolid,
  IconSearchLine,
  IconSearchAddressBookSolid,
  IconSearchAddressBookLine,
  IconScreenCaptureSolid,
  IconScreenCaptureLine,
  IconSaveSolid,
  IconSaveLine,
  IconRulerSolid,
  IconRulerLine,
  IconRubricSolid,
  IconRubricLine,
  IconRubricDarkSolid,
  IconRubricDarkLine,
  IconRssSolid,
  IconRssLine,
  IconRssAddSolid,
  IconRssAddLine,
  IconRotateRightSolid,
  IconRotateRightLine,
  IconRotateLeftSolid,
  IconRotateLeftLine,
  IconRewindSolid,
  IconRewindLine,
  IconReviewScreenSolid,
  IconReviewScreenLine,
  IconResetSolid,
  IconResetLine,
  IconReplySolid,
  IconReplyLine,
  IconReplyAll2Solid,
  IconReplyAll2Line,
  IconReply2Solid,
  IconReply2Line,
  IconRepliedSolid,
  IconRepliedLine,
  IconRemoveLinkSolid,
  IconRemoveLinkLine,
  IconRemoveFromCollectionSolid,
  IconRemoveFromCollectionLine,
  IconRemoveBookmarkSolid,
  IconRemoveBookmarkLine,
  IconRefreshSolid,
  IconRefreshLine,
  IconRecordSolid,
  IconRecordLine,
  IconQuizSolid,
  IconQuizLine,
  IconQuizTitleSolid,
  IconQuizTitleLine,
  IconQuizStatsTimeSolid,
  IconQuizStatsTimeLine,
  IconQuizStatsLowSolid,
  IconQuizStatsLowLine,
  IconVideoCameraSolid,
  IconVideoCameraLine,
  IconVideoCameraOffSolid,
  IconVideoCameraOffLine,
  IconUserSolid,
  IconUserLine,
  IconUserAddSolid,
  IconUserAddLine,
  IconUploadSolid,
  IconUploadLine,
  IconUpdownSolid,
  IconUpdownLine,
  IconUnpublishedSolid,
  IconUnpublishedLine,
  IconUnpublishSolid,
  IconUnpublishLine,
  IconUnmutedSolid,
  IconUnmutedLine,
  IconUnlockSolid,
  IconUnlockLine,
  IconUnderlineSolid,
  IconUnderlineLine,
  IconUnarchiveSolid,
  IconUnarchiveLine,
  IconTwitterSolid,
  IconTwitterLine,
  IconTwitterBoxedSolid,
  IconTwitterBoxedLine,
  IconTroubleSolid,
  IconTroubleLine,
  IconTrashSolid,
  IconTrashLine,
  IconToggleStartSolid,
  IconToggleStartLine,
  IconToggleRightSolid,
  IconToggleRightLine,
  IconToggleLeftSolid,
  IconToggleLeftLine,
  IconToggleEndSolid,
  IconToggleEndLine,
  IconTimerSolid,
  IconTimerLine,
  IconTextareaSolid,
  IconTextareaLine,
  IconTextSolid,
  IconTextLine,
  IconTextSuperscriptSolid,
  IconTextSuperscriptLine,
  IconTextSubscriptSolid,
  IconTextSubscriptLine,
  IconTextStartSolid,
  IconTextStartLine,
  IconTextRightSolid,
  IconTextRightLine,
  IconTextLeftSolid,
  IconTextLeftLine,
  IconTextEndSolid,
  IconTextEndLine,
  IconTextDirectionRtlSolid,
  IconTextDirectionRtlLine,
  IconTextDirectionLtrSolid,
  IconTextDirectionLtrLine,
  IconTextColorSolid,
  IconTextColorLine,
  IconTextCenteredSolid,
  IconTextCenteredLine,
  IconTextBackgroundColorSolid,
  IconTextBackgroundColorLine,
  IconTargetSolid,
  IconTargetLine,
  IconTagSolid,
  IconTagLine,
  IconTableSolid,
  IconTableLine,
  IconTableTopHeaderSolid,
  IconTableTopHeaderLine,
  IconTableSplitCellsSolid,
  IconTableSplitCellsLine,
  IconTableRowPropertiesSolid,
  IconTableRowPropertiesLine,
  IconTableMergeCellsSolid,
  IconTableMergeCellsLine,
  IconTableLeftHeaderSolid,
  IconTableLeftHeaderLine,
  IconTableInsertRowAfterSolid,
  IconTableInsertRowAfterLine,
  IconTableInsertRowAboveSolid,
  IconTableInsertRowAboveLine,
  IconTableInsertColumnBeforeSolid,
  IconTableInsertColumnBeforeLine,
  IconTableInsertColumnAfterSolid,
  IconTableInsertColumnAfterLine,
  IconTableDeleteTableSolid,
  IconTableDeleteTableLine,
  IconTableDeleteRowSolid,
  IconTableDeleteRowLine,
  IconTableDeleteColumnSolid,
  IconTableDeleteColumnLine,
  IconTableCellSelectAllSolid,
  IconTableCellSelectAllLine,
  IconZoomOutSolid,
  IconZoomOutLine,
  IconZoomInSolid,
  IconZoomInLine,
  IconZippedSolid,
  IconZippedLine,
  IconXSolid,
  IconXLine,
  IconWordpressSolid,
  IconWordpressLine,
  IconWindowsSolid,
  IconWindowsLine,
  IconWarningSolid,
  IconWarningLine,
  IconWarningBorderlessSolid,
  IconWarningBorderlessLine,
  IconVideoSolid,
  IconVideoLine,
} from '@instructure/ui';
import React from 'react';

export const ICONS = {
  IconCalendarDaysSolid,
  IconCalendarDaysLine,
  IconCalendarDaySolid,
  IconCalendarDayLine,
  IconCalendarClockSolid,
  IconCalendarClockLine,
  IconCalendarAddSolid,
  IconCalendarAddLine,
  IconCalculatorSolid,
  IconCalculatorLine,
  IconCalculatorDesmosSolid,
  IconCalculatorDesmosLine,
  IconButtonAndIconMakerSolid,
  IconButtonAndIconMakerLine,
  IconBulletListSolid,
  IconBulletListLine,
  IconBulletListSquareSolid,
  IconBulletListSquareLine,
  IconBulletListRomanSolid,
  IconBulletListRomanLine,
  IconBulletListCircleOutlineSolid,
  IconBulletListCircleOutlineLine,
  IconBulletListAlphaSolid,
  IconBulletListAlphaLine,
  IconBoxSolid,
  IconBoxLine,
  IconBookmarkSolid,
  IconBookmarkLine,
  IconBoldSolid,
  IconBoldLine,
  IconBlueprintSolid,
  IconBlueprintLine,
  IconBlueprintLockSolid,
  IconBlueprintLockLine,
  IconBankSolid,
  IconBankLine,
  IconAudioSolid,
  IconAudioLine,
  IconAudioOffSolid,
  IconAudioOffLine,
  IconAttachMediaSolid,
  IconAttachMediaLine,
  IconAssignmentSolid,
  IconAssignmentLine,
  IconArrowUpSolid,
  IconArrowUpLine,
  IconArrowStartSolid,
  IconArrowStartLine,
  IconArrowRightSolid,
  IconArrowRightLine,
  IconArrowOpenUpSolid,
  IconArrowOpenUpLine,
  IconArrowOpenStartSolid,
  IconArrowOpenStartLine,
  IconArrowOpenRightSolid,
  IconArrowOpenRightLine,
  IconArrowOpenLeftSolid,
  IconArrowOpenLeftLine,
  IconArrowOpenEndSolid,
  IconArrowOpenEndLine,
  IconArrowOpenDownSolid,
  IconArrowOpenDownLine,
  IconArrowNestSolid,
  IconArrowNestLine,
  IconArrowLeftSolid,
  IconArrowLeftLine,
  IconArrowEndSolid,
  IconArrowEndLine,
  IconArrowDownSolid,
  IconArrowDownLine,
  IconArchiveSolid,
  IconArchiveLine,
  IconArcSolid,
  IconArcLine,
  IconAppleSolid,
  IconAppleLine,
  IconAnnouncementSolid,
  IconAnnouncementLine,
  IconAnnotateSolid,
  IconAnnotateLine,
  IconAndroidSolid,
  IconAndroidLine,
  IconAnalyticsSolid,
  IconAnalyticsLine,
  IconAlertsSolid,
  IconAlertsLine,
  IconAdminSolid,
  IconAdminLine,
  IconAdminToolsSolid,
  IconAdminToolsLine,
  IconAddressBookSolid,
  IconAddressBookLine,
  IconAddSolid,
  IconAddLine,
  IconAddMediaSolid,
  IconAddMediaLine,
  IconAddFolderSolid,
  IconAddFolderLine,
  IconA11ySolid,
  IconA11yLine,
  IconDropDownSolid,
  IconDropDownLine,
  IconDragHandleSolid,
  IconDragHandleLine,
  IconDownloadSolid,
  IconDownloadLine,
  IconDocumentSolid,
  IconDocumentLine,
  IconDiscussionSolid,
  IconDiscussionLine,
  IconDiscussionXSolid,
  IconDiscussionXLine,
  IconDiscussionSearchSolid,
  IconDiscussionSearchLine,
  IconDiscussionReplySolid,
  IconDiscussionReplyLine,
  IconDiscussionReplyDarkSolid,
  IconDiscussionReplyDarkLine,
  IconDiscussionReply2Solid,
  IconDiscussionReply2Line,
  IconDiscussionNewSolid,
  IconDiscussionNewLine,
  IconDiscussionCheckSolid,
  IconDiscussionCheckLine,
  IconDeactivateUserSolid,
  IconDeactivateUserLine,
  IconDashboardSolid,
  IconDashboardLine,
  IconCropSolid,
  IconCropLine,
  IconCoursesSolid,
  IconCoursesLine,
  IconCopySolid,
  IconCopyLine,
  IconCopyCourseSolid,
  IconCopyCourseLine,
  IconConfigureSolid,
  IconConfigureLine,
  IconComposeSolid,
  IconComposeLine,
  IconCompleteSolid,
  IconCompleteLine,
  IconCompassSolid,
  IconCompassLine,
  IconCommonsSolid,
  IconCommonsLine,
  IconCommentsOnSolid,
  IconCommentsOnLine,
  IconCommentsOffSolid,
  IconCommentsOffLine,
  IconCommentSolid,
  IconCommentLine,
  IconCollectionSolid,
  IconCollectionLine,
  IconCollectionSaveSolid,
  IconCollectionSaveLine,
  IconCollapseSolid,
  IconCollapseLine,
  IconCodeSolid,
  IconCodeLine,
  IconCloudUploadSolid,
  IconCloudUploadLine,
  IconCloudLockSolid,
  IconCloudLockLine,
  IconCloudDownloadSolid,
  IconCloudDownloadLine,
  IconClosedCaptioningSolid,
  IconClosedCaptioningLine,
  IconClosedCaptioningOnSolid,
  IconClosedCaptioningOnLine,
  IconClosedCaptioningOffSolid,
  IconClosedCaptioningOffLine,
  IconClockSolid,
  IconClockLine,
  IconClearTextFormattingSolid,
  IconClearTextFormattingLine,
  IconCircleArrowUpSolid,
  IconCircleArrowUpLine,
  IconCircleArrowDownSolid,
  IconCircleArrowDownLine,
  IconCheckSolid,
  IconCheckLine,
  IconCheckPlusSolid,
  IconCheckPlusLine,
  IconCheckMarkSolid,
  IconCheckMarkLine,
  IconCheckMarkIndeterminateSolid,
  IconCheckMarkIndeterminateLine,
  IconCheckDarkSolid,
  IconCheckDarkLine,
  IconChatSolid,
  IconChatLine,
  IconCertifiedSolid,
  IconCertifiedLine,
  IconCanvasLogoSolid,
  IconCanvasLogoLine,
  IconCalendarReservedSolid,
  IconCalendarReservedLine,
  IconCalendarMonthSolid,
  IconCalendarMonthLine,
  IconHourGlassSolid,
  IconHourGlassLine,
  IconHomeSolid,
  IconHomeLine,
  IconHighlighterSolid,
  IconHighlighterLine,
  IconHeartSolid,
  IconHeartLine,
  IconHeaderSolid,
  IconHeaderLine,
  IconHamburgerSolid,
  IconHamburgerLine,
  IconGroupSolid,
  IconGroupLine,
  IconGroupNewSolid,
  IconGroupNewLine,
  IconGroupDarkNewSolid,
  IconGroupDarkNewLine,
  IconGradebookSolid,
  IconGradebookLine,
  IconGradebookImportSolid,
  IconGradebookImportLine,
  IconGradebookExportSolid,
  IconGradebookExportLine,
  IconGithubSolid,
  IconGithubLine,
  IconFullScreenSolid,
  IconFullScreenLine,
  IconForwardSolid,
  IconForwardLine,
  IconFolderSolid,
  IconFolderLine,
  IconFolderLockedSolid,
  IconFolderLockedLine,
  IconFlagSolid,
  IconFlagLine,
  IconFilterSolid,
  IconFilterLine,
  IconFilmstripSolid,
  IconFilmstripLine,
  IconFilesPublicDomainSolid,
  IconFilesPublicDomainLine,
  IconFilesObtainedPermissionSolid,
  IconFilesObtainedPermissionLine,
  IconFilesFairUseSolid,
  IconFilesFairUseLine,
  IconFilesCreativeCommonsSolid,
  IconFilesCreativeCommonsLine,
  IconFilesCopyrightSolid,
  IconFilesCopyrightLine,
  IconFileLockedSolid,
  IconFileLockedLine,
  IconFeedbackSolid,
  IconFeedbackLine,
  IconFastForwardSolid,
  IconFastForwardLine,
  IconFacebookSolid,
  IconFacebookLine,
  IconFacebookBoxedSolid,
  IconFacebookBoxedLine,
  IconEyeSolid,
  IconEyeLine,
  IconExternalLinkSolid,
  IconExternalLinkLine,
  IconExportSolid,
  IconExportLine,
  IconExportContentSolid,
  IconExportContentLine,
  IconExpandSolid,
  IconExpandLine,
  IconExpandStartSolid,
  IconExpandStartLine,
  IconExpandLeftSolid,
  IconExpandLeftLine,
  IconExpandItemsSolid,
  IconExpandItemsLine,
  IconExitFullScreenSolid,
  IconExitFullScreenLine,
  IconEssaySolid,
  IconEssayLine,
  IconEquellaSolid,
  IconEquellaLine,
  IconEquationSolid,
  IconEquationLine,
  IconEportfolioSolid,
  IconEportfolioLine,
  IconEndSolid,
  IconEndLine,
  IconEmptySolid,
  IconEmptyLine,
  IconEmailSolid,
  IconEmailLine,
  IconElevateLogoSolid,
  IconElevateLogoLine,
  IconEducatorsSolid,
  IconEducatorsLine,
  IconEditSolid,
  IconEditLine,
  IconDuplicateSolid,
  IconDuplicateLine,
  IconMoveDownSolid,
  IconMoveDownLine,
  IconMoveDownBottomSolid,
  IconMoveDownBottomLine,
  IconMoreSolid,
  IconMoreLine,
  IconModuleSolid,
  IconModuleLine,
  IconMinimizeSolid,
  IconMinimizeLine,
  IconMiniArrowUpSolid,
  IconMiniArrowUpLine,
  IconMiniArrowStartSolid,
  IconMiniArrowStartLine,
  IconMiniArrowRightSolid,
  IconMiniArrowRightLine,
  IconMiniArrowLeftSolid,
  IconMiniArrowLeftLine,
  IconMiniArrowEndSolid,
  IconMiniArrowEndLine,
  IconMiniArrowDownSolid,
  IconMiniArrowDownLine,
  IconMiniArrowDoubleSolid,
  IconMiniArrowDoubleLine,
  IconMicSolid,
  IconMicLine,
  IconMicOffSolid,
  IconMicOffLine,
  IconMessageSolid,
  IconMessageLine,
  IconMediaSolid,
  IconMediaLine,
  IconMatureSolid,
  IconMatureLine,
  IconMatureLightSolid,
  IconMatureLightLine,
  IconMaterialsRequiredSolid,
  IconMaterialsRequiredLine,
  IconMaterialsRequiredLightSolid,
  IconMaterialsRequiredLightLine,
  IconMasteryPathsSolid,
  IconMasteryPathsLine,
  IconMasteryLogoSolid,
  IconMasteryLogoLine,
  IconMasqueradeSolid,
  IconMasqueradeLine,
  IconMarkerSolid,
  IconMarkerLine,
  IconMarkAsReadSolid,
  IconMarkAsReadLine,
  IconLtiSolid,
  IconLtiLine,
  IconLockSolid,
  IconLockLine,
  IconLinkedinSolid,
  IconLinkedinLine,
  IconLinkSolid,
  IconLinkLine,
  IconLineReaderSolid,
  IconLineReaderLine,
  IconLikeSolid,
  IconLikeLine,
  IconLifePreserverSolid,
  IconLifePreserverLine,
  IconLaunchSolid,
  IconLaunchLine,
  IconKeyboardShortcutsSolid,
  IconKeyboardShortcutsLine,
  IconItalicSolid,
  IconItalicLine,
  IconInvitationSolid,
  IconInvitationLine,
  IconIntegrationsSolid,
  IconIntegrationsLine,
  IconInstructureSolid,
  IconInstructureLine,
  IconInstructureLogoSolid,
  IconInstructureLogoLine,
  IconInfoSolid,
  IconInfoLine,
  IconInfoBorderlessSolid,
  IconInfoBorderlessLine,
  IconIndentSolid,
  IconIndentLine,
  IconIndent2Solid,
  IconIndent2Line,
  IconInboxSolid,
  IconInboxLine,
  IconImportantDatesSolid,
  IconImportantDatesLine,
  IconImportSolid,
  IconImportLine,
  IconImportContentSolid,
  IconImportContentLine,
  IconImpactLogoSolid,
  IconImpactLogoLine,
  IconImmersiveReaderSolid,
  IconImmersiveReaderLine,
  IconImageSolid,
  IconImageLine,
  IconQuizStatsHighSolid,
  IconQuizStatsHighLine,
  IconQuizStatsDeviationSolid,
  IconQuizStatsDeviationLine,
  IconQuizStatsCronbachsAlphaSolid,
  IconQuizStatsCronbachsAlphaLine,
  IconQuizStatsAvgSolid,
  IconQuizStatsAvgLine,
  IconQuizInstructionsSolid,
  IconQuizInstructionsLine,
  IconQuestionSolid,
  IconQuestionLine,
  IconPublishSolid,
  IconPublishLine,
  IconProtractorSolid,
  IconProtractorLine,
  IconProgressSolid,
  IconProgressLine,
  IconPrinterSolid,
  IconPrinterLine,
  IconPrerequisiteSolid,
  IconPrerequisiteLine,
  IconPostToSisSolid,
  IconPostToSisLine,
  IconPlusSolid,
  IconPlusLine,
  IconPlaySolid,
  IconPlayLine,
  IconPinterestSolid,
  IconPinterestLine,
  IconPinSolid,
  IconPinLine,
  IconPermissionsSolid,
  IconPermissionsLine,
  IconPeerReviewSolid,
  IconPeerReviewLine,
  IconPeerGradedSolid,
  IconPeerGradedLine,
  IconPdfSolid,
  IconPdfLine,
  IconPauseSolid,
  IconPauseLine,
  IconPartialSolid,
  IconPartialLine,
  IconPaperclipSolid,
  IconPaperclipLine,
  IconPaintSolid,
  IconPaintLine,
  IconPageUpSolid,
  IconPageUpLine,
  IconPageDownSolid,
  IconPageDownLine,
  IconOvalHalfSolid,
  IconOvalHalfLine,
  IconOutdent2Solid,
  IconOutdent2Line,
  IconOutdentSolid,
  IconOutdentLine,
  IconOutcomesSolid,
  IconOutcomesLine,
  IconOpenFolderSolid,
  IconOpenFolderLine,
  IconOffSolid,
  IconOffLine,
  IconNumberedListSolid,
  IconNumberedListLine,
  IconNotepadSolid,
  IconNotepadLine,
  IconNoteSolid,
  IconNoteLine,
  IconNoteLightSolid,
  IconNoteLightLine,
  IconNoteDarkSolid,
  IconNoteDarkLine,
  IconNotGradedSolid,
  IconNotGradedLine,
  IconNoSolid,
  IconNoLine,
  IconNextUnreadSolid,
  IconNextUnreadLine,
  IconMutedSolid,
  IconMutedLine,
  IconMsWordSolid,
  IconMsWordLine,
  IconMsPptSolid,
  IconMsPptLine,
  IconMsExcelSolid,
  IconMsExcelLine,
  IconMoveUpSolid,
  IconMoveUpLine,
  IconMoveUpTopSolid,
  IconMoveUpTopLine,
  IconMoveStartSolid,
  IconMoveStartLine,
  IconMoveRightSolid,
  IconMoveRightLine,
  IconMoveLeftSolid,
  IconMoveLeftLine,
  IconMoveEndSolid,
  IconMoveEndLine,
  IconSyllabusSolid,
  IconSyllabusLine,
  IconSubtitlesSolid,
  IconSubtitlesLine,
  IconSubaccountsSolid,
  IconSubaccountsLine,
  IconStudioSolid,
  IconStudioLine,
  IconStudentViewSolid,
  IconStudentViewLine,
  IconStrikethroughSolid,
  IconStrikethroughLine,
  IconStopSolid,
  IconStopLine,
  IconStatsSolid,
  IconStatsLine,
  IconStarSolid,
  IconStarLine,
  IconStarLightSolid,
  IconStarLightLine,
  IconStandardsSolid,
  IconStandardsLine,
  IconSpeedGraderSolid,
  IconSpeedGraderLine,
  IconSortSolid,
  IconSortLine,
  IconSkypeSolid,
  IconSkypeLine,
  IconSisSyncedSolid,
  IconSisSyncedLine,
  IconSisNotSyncedSolid,
  IconSisNotSyncedLine,
  IconSisImportedSolid,
  IconSisImportedLine,
  IconShareSolid,
  IconShareLine,
  IconShapeRectangleSolid,
  IconShapeRectangleLine,
  IconShapePolygonSolid,
  IconShapePolygonLine,
  IconShapeOvalSolid,
  IconShapeOvalLine,
  IconSettingsSolid,
  IconSettingsLine,
  IconSettings2Solid,
  IconSettings2Line,
  IconSearchSolid,
  IconSearchLine,
  IconSearchAddressBookSolid,
  IconSearchAddressBookLine,
  IconScreenCaptureSolid,
  IconScreenCaptureLine,
  IconSaveSolid,
  IconSaveLine,
  IconRulerSolid,
  IconRulerLine,
  IconRubricSolid,
  IconRubricLine,
  IconRubricDarkSolid,
  IconRubricDarkLine,
  IconRssSolid,
  IconRssLine,
  IconRssAddSolid,
  IconRssAddLine,
  IconRotateRightSolid,
  IconRotateRightLine,
  IconRotateLeftSolid,
  IconRotateLeftLine,
  IconRewindSolid,
  IconRewindLine,
  IconReviewScreenSolid,
  IconReviewScreenLine,
  IconResetSolid,
  IconResetLine,
  IconReplySolid,
  IconReplyLine,
  IconReplyAll2Solid,
  IconReplyAll2Line,
  IconReply2Solid,
  IconReply2Line,
  IconRepliedSolid,
  IconRepliedLine,
  IconRemoveLinkSolid,
  IconRemoveLinkLine,
  IconRemoveFromCollectionSolid,
  IconRemoveFromCollectionLine,
  IconRemoveBookmarkSolid,
  IconRemoveBookmarkLine,
  IconRefreshSolid,
  IconRefreshLine,
  IconRecordSolid,
  IconRecordLine,
  IconQuizSolid,
  IconQuizLine,
  IconQuizTitleSolid,
  IconQuizTitleLine,
  IconQuizStatsTimeSolid,
  IconQuizStatsTimeLine,
  IconQuizStatsLowSolid,
  IconQuizStatsLowLine,
  IconVideoCameraSolid,
  IconVideoCameraLine,
  IconVideoCameraOffSolid,
  IconVideoCameraOffLine,
  IconUserSolid,
  IconUserLine,
  IconUserAddSolid,
  IconUserAddLine,
  IconUploadSolid,
  IconUploadLine,
  IconUpdownSolid,
  IconUpdownLine,
  IconUnpublishedSolid,
  IconUnpublishedLine,
  IconUnpublishSolid,
  IconUnpublishLine,
  IconUnmutedSolid,
  IconUnmutedLine,
  IconUnlockSolid,
  IconUnlockLine,
  IconUnderlineSolid,
  IconUnderlineLine,
  IconUnarchiveSolid,
  IconUnarchiveLine,
  IconTwitterSolid,
  IconTwitterLine,
  IconTwitterBoxedSolid,
  IconTwitterBoxedLine,
  IconTroubleSolid,
  IconTroubleLine,
  IconTrashSolid,
  IconTrashLine,
  IconToggleStartSolid,
  IconToggleStartLine,
  IconToggleRightSolid,
  IconToggleRightLine,
  IconToggleLeftSolid,
  IconToggleLeftLine,
  IconToggleEndSolid,
  IconToggleEndLine,
  IconTimerSolid,
  IconTimerLine,
  IconTextareaSolid,
  IconTextareaLine,
  IconTextSolid,
  IconTextLine,
  IconTextSuperscriptSolid,
  IconTextSuperscriptLine,
  IconTextSubscriptSolid,
  IconTextSubscriptLine,
  IconTextStartSolid,
  IconTextStartLine,
  IconTextRightSolid,
  IconTextRightLine,
  IconTextLeftSolid,
  IconTextLeftLine,
  IconTextEndSolid,
  IconTextEndLine,
  IconTextDirectionRtlSolid,
  IconTextDirectionRtlLine,
  IconTextDirectionLtrSolid,
  IconTextDirectionLtrLine,
  IconTextColorSolid,
  IconTextColorLine,
  IconTextCenteredSolid,
  IconTextCenteredLine,
  IconTextBackgroundColorSolid,
  IconTextBackgroundColorLine,
  IconTargetSolid,
  IconTargetLine,
  IconTagSolid,
  IconTagLine,
  IconTableSolid,
  IconTableLine,
  IconTableTopHeaderSolid,
  IconTableTopHeaderLine,
  IconTableSplitCellsSolid,
  IconTableSplitCellsLine,
  IconTableRowPropertiesSolid,
  IconTableRowPropertiesLine,
  IconTableMergeCellsSolid,
  IconTableMergeCellsLine,
  IconTableLeftHeaderSolid,
  IconTableLeftHeaderLine,
  IconTableInsertRowAfterSolid,
  IconTableInsertRowAfterLine,
  IconTableInsertRowAboveSolid,
  IconTableInsertRowAboveLine,
  IconTableInsertColumnBeforeSolid,
  IconTableInsertColumnBeforeLine,
  IconTableInsertColumnAfterSolid,
  IconTableInsertColumnAfterLine,
  IconTableDeleteTableSolid,
  IconTableDeleteTableLine,
  IconTableDeleteRowSolid,
  IconTableDeleteRowLine,
  IconTableDeleteColumnSolid,
  IconTableDeleteColumnLine,
  IconTableCellSelectAllSolid,
  IconTableCellSelectAllLine,
  IconZoomOutSolid,
  IconZoomOutLine,
  IconZoomInSolid,
  IconZoomInLine,
  IconZippedSolid,
  IconZippedLine,
  IconXSolid,
  IconXLine,
  IconWordpressSolid,
  IconWordpressLine,
  IconWindowsSolid,
  IconWindowsLine,
  IconWarningSolid,
  IconWarningLine,
  IconWarningBorderlessSolid,
  IconWarningBorderlessLine,
  IconVideoSolid,
  IconVideoLine,
};

export type Icon = keyof typeof ICONS;
interface IconProps {
  icon: Icon;
}

const IUIcon = ({ icon, ...rest }: IconProps) => {
  const IconComponent = ICONS[icon];
  if (IconComponent) {
    return <IconComponent {...rest} />;
  }
  return null;
};
export default IUIcon;
