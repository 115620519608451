import React from 'react';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { ProfileSetupProps } from './types';
import IUProfileSetup from './IUProfileSetup';
import MUProfileSetup from './MUProfileSetup';

const ProfileSetup = ({ errors, user }: ProfileSetupProps) => {
  const useNewLogin = useFeatureFlags('ff_new_login');
  return useNewLogin ? <IUProfileSetup errors={errors} user={user} /> : <MUProfileSetup errors={errors} user={user} />;
};

export default ProfileSetup;
