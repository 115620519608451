import React from 'react';
import useFeatureFlags from 'hooks/useFeatureFlags';
import IUResendUnlock from './IUResendUnlock';
import MUResendUnlock from './MUResendUnlock';

interface ResendUnlockProps {
  errors: string[];
}

const ResendUnlock = ({ errors }: ResendUnlockProps) => {
  const useNewLogin = useFeatureFlags('ff_new_login');
  const { isPartner } = gon;
  if (useNewLogin || isPartner) return <IUResendUnlock errors={errors} />;
  return <MUResendUnlock errors={errors} />;
};

export default ResendUnlock;
