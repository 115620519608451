import { Helmet } from 'react-helmet';
import React, { useState, useEffect } from 'react';
import { pluck, includes, isEmpty } from 'ramda';
import queryString from 'query-string';
import { ArrowBack } from '@material-ui/icons';
import * as Routes from 'Routes';

import Pardot from 'services/Pardot';
import { authenticityToken } from 'utils/FetchHelpers';
import type { User } from 'presenters/UserPresenter';
import TermsAndPrivacyLinks from 'components/TermsAndPrivacyLinks';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Form, { OTHER_USER_TYPE } from 'forms/SignUp/SignUpForm';
import { set, isBlank } from 'utils/Utils';
import { ErrorsType } from 'containers/PreApplication/sharedComponents/RoleSelector/type';
import RoleSelector from 'containers/PreApplication/sharedComponents/RoleSelector';
import roles from 'containers/PreApplication/sharedComponents/IURoleRender/roles';
import useStyles from './useStyles';

interface MUSignUpPageProps {
  user: User | null;
}

const MUSignUpPage = ({ user }: MUSignUpPageProps) => {
  const classes = useStyles();
  const { isPartner } = gon;

  const [form, setForm] = useState(Form.defaultAttributes(user || {}));
  const [disabled, setDisabled] = useState(false);
  const [errors, setErrors] = useState((user?.errors as ErrorsType) || {});

  const loginPath = isPartner ? Routes.new_partner_session_path() : Routes.new_user_session_path();

  const setPrimaryRole = (primaryRole: string | string[] | null) => {
    if (isBlank(primaryRole)) {
      return;
    }

    const roleIds = pluck('id', roles);
    if (includes(primaryRole, roleIds)) {
      handleRoleChange(primaryRole as string);
      setDisabled(true);
    }
  };

  useEffect(() => {
    const { search } = window.location;
    const primaryRole = (queryString.parse(search).primary_role || '').toString();
    setPrimaryRole(primaryRole);
  }, []);

  useEffect(() => {
    if (isPartner) {
      setPrimaryRole('product_developer');
    }
  }, [isPartner]);

  const handleRoleChange = (role: string) => {
    const newState = set(form, ['userType'], form.userType !== role ? role : '');
    setForm(newState);
  };

  const handleSubmit = (e: React.FormEvent) => {
    const formErrors = Form.validate(form);
    if (isEmpty(formErrors)) {
      const params = Form.attributesToSubmit(form);
      Pardot.signUp(params);
    } else {
      e.preventDefault();
      setErrors(formErrors);
    }
  };

  return (
    <>
      <Helmet>
        <title>Sign Up | Select Your Role</title>
      </Helmet>
      {form.userType && form.userType !== OTHER_USER_TYPE && !isPartner && (
        <Button color="transparent" size="xsmall" onClick={() => handleRoleChange('')} className={classes.backButton}>
          <ArrowBack className={classes.arrow} />
          Back
        </Button>
      )}
      <form action={Routes.user_registration_path()} method="post" onSubmit={handleSubmit} className={classes.form}>
        <input type="hidden" name="authenticity_token" value={authenticityToken()} />

        <div className={classes.root}>
          <Icon icon="welcome" />
          <h1 className={classes.title}>Join the Community!</h1>
          <p className={classes.description}>
            Please select the role that best describes your responsibilities at your organization.
          </p>
          <div className={classes.formContainer}>
            <RoleSelector
              disabled={disabled}
              user={form}
              onChange={(name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
                const newState = set(form, [name], e.target.value);
                setForm(newState);
              }}
              onChangeRole={handleRoleChange}
              buttonText="Create Account"
              errors={errors}
              showEmail
            />
          </div>
        </div>
      </form>
      <div className={classes.footer}>
        <div className={classes.signIn}>
          Already have an account?{' '}
          <Button variant="link" href={loginPath} className={classes.footerLink}>
            Sign In
          </Button>
        </div>
        <div className={classes.serviceLinks}>
          <TermsAndPrivacyLinks />
        </div>
      </div>
    </>
  );
};

export default MUSignUpPage;
