import React from 'react';
import PropTypes from 'prop-types';
import useFeatureFlags from 'hooks/useFeatureFlags';
import IUSignIn from './IUSignIn';
import MUSignIn from './MUSignIn';

/*
  This is a wrapper component that will render the new login page if the feature flag is enabled, otherwise it will render the old login page.
  When the feature flag is to be removed, remove this component and the MUSignIn component and renaming the IUSignIn component to SignIn.
 */

const SignIn = ({ user }) => {
  const useNewLogin = useFeatureFlags('ff_new_login');
  const { isPartner } = gon;
  if (useNewLogin || isPartner) return <IUSignIn user={user} isPartner={isPartner} />;
  return <MUSignIn user={user} />;
};

SignIn.propTypes = {
  user: PropTypes.shape(),
};

export default SignIn;
