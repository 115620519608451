import React, { useState, useEffect, useCallback, ReactNode } from 'react';
import debounce from 'lodash.debounce';
import { path } from 'ramda';
import { Select, Spinner, Alert, IconArrowOpenUpLine, IconArrowOpenDownLine } from '@instructure/ui';
import IUIcon, { Icon } from 'components/IUIcon/IUIcon';

interface IUAutocompleteAsyncProps {
  getOptions: (searchTerm: string) => Promise<any>;
  onAdd: (option: Option) => void;
  startIcon?: string;
  endIcon?: string;
  placeholder?: string;
  dataNode?: string;
  id?: string;
  disabled?: boolean;
  inputLabel?: string;
  labelPath?: string[];
  value?: string;
  helperText?: string;
  error?: boolean;
  fullWidthMenu?: boolean;
  // menuAbove?: boolean; ; Note: Not Implemented
  // saveValue?: boolean; ; Note: Not Implemented
  // showMissingOption?: boolean; Note: Not Implemented
  // onSelectMissingOption?: (searchTerm: string) => void;
  // disableUnderline?: boolean; Note: Not Implemented
  // getAdditionalOptions?: (searchTerm: string, page: number) => Promise<any>; Note: Not Implemented
  // hasInfiniteScroll?: boolean; Note: Not Implemented
  // outlined?: boolean; Note: Not Implemented
}

interface Option {
  id: string;
  name: string;
}

const matchValue = (
  options: Option[],
  inputValue: string,
  selectedOptionId: string | null,
  selectedOptionLabel: string,
) => {
  if (options.length === 1) {
    const onlyOption = options[0];
    if (onlyOption.name.toLowerCase() === inputValue.toLowerCase()) {
      return {
        inputValue: onlyOption.name,
        selectedOptionId: onlyOption.id,
      };
    }
  }

  if (inputValue.length === 0) {
    return { selectedOptionId: null, filteredOptions: [] };
  }

  if (selectedOptionId) {
    return { inputValue: selectedOptionLabel };
  }

  return {};
};

const IUAutocompleteAsync = ({
  getOptions,
  onAdd,
  startIcon,
  endIcon,
  placeholder,
  dataNode,
  id,
  disabled,
  inputLabel,
  labelPath = ['name'],
  value,
  helperText,
  error,
  fullWidthMenu,
}: IUAutocompleteAsyncProps) => {
  const [inputValue, setInputValue] = useState(value || '');
  const [isShowingOptions, setIsShowingOptions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [highlightedOptionId, setHighlightedOptionId] = useState<string | null>(null);
  const [selectedOptionId, setSelectedOptionId] = useState<string | null>(null);
  const [selectedOptionLabel, setSelectedOptionLabel] = useState('');
  const [announcement, setAnnouncement] = useState<string | null>(null);
  const [options, setOptions] = useState<any[]>([]);
  const [executeDebouncer, setExecuteDebouncer] = useState(false);

  const debounceInputChange = useCallback(
    debounce(() => setExecuteDebouncer(true), 500),
    [],
  );

  useEffect(() => {
    if (executeDebouncer) {
      setExecuteDebouncer(false);
      internalHandleChange();
    }
  }, [executeDebouncer]);

  const getOptionName = (option: any): ReactNode => {
    return path(labelPath, option);
  };

  const getOptionById = useCallback(
    (queryId: string) => options.find(({ id: nId }) => nId.toString() === queryId),
    [options],
  );

  const handleHideOptions = () => {
    setIsShowingOptions(false);
    setHighlightedOptionId(null);
    setAnnouncement('List collapsed.');
    const newState = matchValue(options, inputValue, selectedOptionId, selectedOptionLabel);
    if (newState.inputValue !== undefined) setInputValue(newState.inputValue);
    if (newState.selectedOptionId !== undefined) setSelectedOptionId(newState.selectedOptionId);
    if (newState.filteredOptions !== undefined) setOptions(newState.filteredOptions);
  };

  const handleHighlightOption = (event: React.SyntheticEvent, data: { id?: string; direction?: 1 | -1 }) => {
    const nId = data.id;
    if (!nId) return;
    const option = getOptionById(nId);
    if (!option) return;
    setHighlightedOptionId(nId);
    setInputValue(event.type === 'keydown' ? option.name : inputValue);
    setAnnouncement(option.name);
  };

  const handleSelectOption = (event: React.SyntheticEvent, data: { id?: string; direction?: 1 | -1 }) => {
    const nId = data.id;
    const option = getOptionById(nId || '');
    if (!option) return;
    setSelectedOptionId(nId || '');
    setSelectedOptionLabel(option.name);
    setInputValue(option.name);
    setIsShowingOptions(false);
    setOptions([option]);
    onAdd(option);
  };

  const internalHandleChange = () => {
    setIsLoading(true);
    setIsShowingOptions(true);
    setHighlightedOptionId(null);
    setAnnouncement('Loading options.');

    getOptions(inputValue)
      .then(response => {
        setOptions(response);
        setAnnouncement(`${response.length} options available.`);
      })
      .catch(() => {
        setOptions([]);
        setAnnouncement('Failed to load options.');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Select
        id={id}
        data-node={dataNode}
        renderLabel={inputLabel}
        assistiveText="Type to search"
        inputValue={inputValue}
        isShowingOptions={isShowingOptions}
        onBlur={() => {
          setHighlightedOptionId(null);
        }}
        onInputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const myValue = event.target.value;
          setInputValue(myValue);
          debounceInputChange();
        }}
        onRequestShowOptions={() => {
          setIsShowingOptions(true);
          internalHandleChange();
        }}
        onRequestHideOptions={handleHideOptions}
        onRequestHighlightOption={handleHighlightOption}
        onRequestSelectOption={handleSelectOption}
        isInline={!fullWidthMenu}
        visibleOptionsCount={10}
        placeholder={placeholder}
        interaction={disabled ? 'disabled' : 'enabled'}
        messages={error ? [{ text: helperText, type: 'error' }] : []}
        renderBeforeInput={() => {
          if (startIcon) {
            return <IUIcon icon={startIcon as Icon} />;
          }
          return null;
        }}
        renderAfterInput={() => {
          if (endIcon) {
            return <IUIcon icon={endIcon as Icon} />;
          }
          if (isShowingOptions) {
            return <IconArrowOpenUpLine inline={false} />;
          }
          return <IconArrowOpenDownLine inline={false} />;
        }}
      >
        {options && options.length > 0 ? (
          options.map(option => {
            const optionId = option.id.toString();
            const result = getOptionName(option);
            return (
              <Select.Option
                id={optionId}
                key={option.id}
                isHighlighted={optionId === highlightedOptionId}
                isSelected={optionId === selectedOptionId}
                isDisabled={false}
              >
                {result}
              </Select.Option>
            );
          })
        ) : (
          <Select.Option id="empty-option" key="empty-option">
            {isLoading ? (
              <Spinner renderTitle="Loading" size="x-small" />
            ) : (
              (() => {
                if (inputValue !== '') {
                  return 'No results';
                }
                return 'Type to search';
              })()
            )}
          </Select.Option>
        )}
      </Select>
      <Alert screenReaderOnly>{announcement}</Alert>
    </>
  );
};

export default IUAutocompleteAsync;
