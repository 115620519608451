import React from 'react';
import { Text, View } from '@instructure/ui';

interface NavigationLinkProps {
  text: string;
  name: string;
  currentName: string;
  href: string;
  dataNode: string;
}

const NavigationLink = ({ text, name, currentName, href, dataNode }: NavigationLinkProps) => {
  const isSelected = name === currentName;
  return (
    <View margin="none none none medium" as="div">
      {isSelected ? (
        <a
          data-node={dataNode}
          href={href}
          role="button"
          aria-current="page"
          style={{
            padding: '0 0 5px 0',
            borderBottom: '2px solid rgb(0, 0, 0)',
          }}
        >
          <Text weight={isSelected ? 'bold' : 'normal'}>{text}</Text>
        </a>
      ) : (
        <a data-node={dataNode} href={href} role="button">
          <Text weight={isSelected ? 'bold' : 'normal'}>{text}</Text>
        </a>
      )}
    </View>
  );
};

export default NavigationLink;
