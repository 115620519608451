import React from 'react';
import { Flex, View } from '@instructure/ui';

interface IUPanelFrameProps {
  children?: React.ReactNode;
  cancelButton?: React.ReactNode;
  extraButton?: React.ReactNode;
  nextButton?: React.ReactNode;
  padding?: string;
}

const IUPanelFrame = ({ padding, children, extraButton, cancelButton, nextButton }: IUPanelFrameProps) => {
  const params = {};
  if (padding) {
    params['height'] = `calc(100vh - ${padding})`;
  }
  return (
    <>
      <Flex data-node="parent" as="div" direction="column" justifyItems="center" {...params}>
        <Flex.Item shouldShrink shouldGrow data-node="async-input" overflowX="hidden">
          <View as="div" margin="none">
            {children}
          </View>
        </Flex.Item>
        <Flex.Item data-node="buttons">
          <Flex as="div" margin="none" direction="row">
            <Flex.Item shouldShrink shouldGrow>
              {cancelButton}
            </Flex.Item>
            <Flex.Item shouldShrink shouldGrow>
              {extraButton}
            </Flex.Item>
            <Flex.Item>
              <>{nextButton}</>
            </Flex.Item>
          </Flex>
        </Flex.Item>
      </Flex>
    </>
  );
};

export default IUPanelFrame;
