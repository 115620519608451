import React, { useState } from 'react';
import { isEmpty } from 'ramda';
import { Button, Checkbox, Text, View } from '@instructure/ui';
import IUPanelFrame from '../../../../sharedComponents/IUPanelFrame';
import { Company } from '../../Company';

interface ConfirmCompanyProps {
  selectedCompany: Company;
  onSubmitForm: () => void;
  onGoBack: () => void;
  padding?: string;
}

const ConfirmCompany = ({ padding, selectedCompany, onGoBack, onSubmitForm }: ConfirmCompanyProps) => {
  const [allowSendInfo, setAllowSendInfo] = useState(false);

  return (
    <>
      <IUPanelFrame
        padding={padding}
        cancelButton={
          <View data-node="button-wrapper" as="div" padding="xx-small">
            <Button onClick={onGoBack}>Go Back</Button>
          </View>
        }
        extraButton={
          <View data-node="button-wrapper" as="div" padding="xx-small">
            <Button href="mailto:support@learnplatform.com">Contact Support</Button>
          </View>
        }
        nextButton={
          <View data-node="button-wrapper" as="div" padding="xx-small">
            <Button
              data-node="claim_button"
              size="medium"
              color="primary"
              type="submit"
              onClick={onSubmitForm}
              interaction={!isEmpty(selectedCompany) && allowSendInfo ? 'enabled' : 'disabled'}
            >
              Request Access
            </Button>
          </View>
        }
      >
        <Text weight="bold" size="large">
          {selectedCompany.name}
        </Text>
        <br />

        <View as="div" padding="medium none none none">
          Your email address does not match what we have on record for that company.
        </View>
        <View as="div" padding="medium none">
          Would you like to request access?
        </View>

        <View data-node="bad" as="div" padding="xx-small none">
          <Checkbox
            size="medium"
            label={`I understand and consent to sharing my account information with ${selectedCompany.name}`}
            checked={allowSendInfo}
            onChange={() => setAllowSendInfo(!allowSendInfo)}
          />
        </View>
      </IUPanelFrame>
    </>
  );
};

export default ConfirmCompany;
