import React, { useState } from 'react';
import { CondensedButton, Table, View, Text } from '@instructure/ui';
import MembershipPresenter from 'presenters/MembershipPresenter';
import Loader from 'components/IULoader';
import OrganizationAvatar from 'components/OrganizationAvatar';
import OrganizationPresenter from 'presenters/OrganizationPresenter';
import StatusLabel from 'components/StatusLabel';
import IUNoData from 'components/IUNoData/IUNoData';

interface IUMembershipTableProps {
  memberships: any;
  onDestroy: any;
  loading: boolean;
}

const IUMembershipTable = ({ memberships, onDestroy, loading }: IUMembershipTableProps) => {
  const [deleting, setDeleting] = useState(false);
  const handleDestroy = (membershipId: number) => onDestroy(membershipId);

  const renderDeleteConfirmation = (membershipId: number) => {
    return (
      <Table.Cell>
        <Text as="span" size="small">
          Remove membership?
        </Text>
        <View as="div" display="flex">
          <CondensedButton size="small" color="secondary" onClick={() => setDeleting(false)}>
            Cancel
          </CondensedButton>
          <CondensedButton size="small" color="primary" onClick={() => handleDestroy(membershipId)}>
            Remove
          </CondensedButton>
        </View>
      </Table.Cell>
    );
  };

  const renderActions = membership => {
    return (
      <Table.Cell>
        <CondensedButton onClick={() => setDeleting(membership.id)}>Remove</CondensedButton>
      </Table.Cell>
    );
  };

  const renderContent = () => {
    if (loading) {
      return (
        <Table.Row>
          <Table.Cell colSpan={3}>
            <Loader size="small" />
          </Table.Cell>
        </Table.Row>
      );
    }
    if (!memberships || memberships.length === 0) {
      return <IUNoData type="table" title="No Membership" />;
    }
    return memberships.map(membership => {
      const organization = MembershipPresenter.organization(membership);
      return (
        <Table.Row key={organization.id} data-node="member_rows">
          <Table.Cell>
            <OrganizationAvatar organization={organization} size="sm" />
            {OrganizationPresenter.name(organization)}
          </Table.Cell>
          <Table.Cell>
            <StatusLabel status={MembershipPresenter.statusObject(membership)} type="dot" />
          </Table.Cell>
          {deleting === membership.id ? renderDeleteConfirmation(membership.id) : renderActions(membership)}
        </Table.Row>
      );
    });
  };

  return (
    <Table id="membership_table">
      <Table.Head>
        <Table.Row>
          <Table.ColHeader id="organization">Organization</Table.ColHeader>
          <Table.ColHeader id="status">Status</Table.ColHeader>
          <Table.ColHeader id="actions">Actions</Table.ColHeader>
        </Table.Row>
      </Table.Head>
      <Table.Body>{renderContent()}</Table.Body>
    </Table>
  );
};

export default IUMembershipTable;
