import React, { useMemo } from 'react';
import { Button, CloseButton, Heading, Modal, ModalProps, View } from '@instructure/ui';

interface IUModalProps extends ModalProps {
  title?: string;
  titleClassName?: string;
  button?: boolean;
  buttonText?: string;
  buttonAlign?: 'left' | 'center' | 'right';
  headerTabContent?: string;
  customStyle?: string;
  noPadding?: boolean;
  headerButtons?: JSX.Element;
  onClose(): void;
}

type Alignment = 'start' | 'center' | 'end' | undefined;

const IUModal = ({
  children,
  title = '',
  onClose,
  button = true,
  buttonText = 'Ok',
  buttonAlign = 'right',
  open,
  headerTabContent,
  noPadding,
  headerButtons,
  ...rest
}: IUModalProps) => {
  const mappedButtonAlign = useMemo<Alignment>(() => {
    switch (buttonAlign) {
      case 'left':
        return 'start';
      case 'center':
        return 'center';
      default:
        return 'end';
    }
  }, [buttonAlign]);

  return (
    <div data-node="modal_window">
      <Modal onClose={onClose} open={open} {...rest}>
        <Modal.Header>
          {headerTabContent}
          {title ? (
            <div>
              <Heading data-node="modal-title">{title}</Heading>
              <div data-node="modal_header_buttons">
                {headerButtons}
                <CloseButton placement="end" offset="small" onClick={onClose} screenReaderLabel="Close modal" />
              </div>
            </div>
          ) : (
            <div>
              <div data-node="modal_header_buttons">
                {headerButtons}
                <CloseButton placement="end" offset="small" onClick={onClose} screenReaderLabel="Close modal" />
              </div>
            </div>
          )}
        </Modal.Header>
        <Modal.Body padding={noPadding ? 'none' : 'medium'}>{children}</Modal.Body>
        <Modal.Footer>
          <View as="div" textAlign={mappedButtonAlign} margin="none none none none">
            {button && (
              <div>
                <Button onClick={onClose} id="modal-close-button" data-node="ok_button">
                  {buttonText}
                </Button>
              </div>
            )}
          </View>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default IUModal;
