import React from 'react';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import IUEmailConfirmation from './IUEmailConfirmation';
import MUEmailConfirmation from './MUEmailConfirmation';

interface EmailConfirmationProps {
  email: string;
}

const EmailConfirmation = ({ email }: EmailConfirmationProps) => {
  const useNewLogin = useFeatureFlags('ff_new_login');
  const { isPartner } = gon;
  if (useNewLogin || isPartner) return <IUEmailConfirmation email={email} isPartner={isPartner} />;
  return <MUEmailConfirmation email={email} />;
};

export default EmailConfirmation;
