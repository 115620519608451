import React, { useState } from 'react';
import * as Routes from 'Routes';
import Button from 'components/Button';
import FormGroup from 'components/FormGroup';
import TextField from 'components/TextField';
import useFeatureFlags from 'hooks/useFeatureFlags';

import { PasswordFieldsProps } from '../RoleSelector/type';
import IUPasswordFields from '../IUPasswordFields';
import useStyles from './useStyles';

const MUPasswordFields = ({ backToLogin, buttonText, submitButton, className }: PasswordFieldsProps) => {
  const classes = useStyles();
  const [password, updatePassword] = useState('');
  const [confirmPassword, updateConfirmPassword] = useState('');
  const [checkLength, setCheckLength] = useState(false);

  return (
    <>
      <FormGroup className={className}>
        <TextField
          data-node="password"
          onChange={e => updatePassword(e.target.value)}
          onFocus={() => setCheckLength(false)}
          onBlur={() => setCheckLength(true)}
          name="user[password]"
          value={password}
          className={classes.textField}
          id="user_password"
          label="New Password"
          type="password"
          fullWidth
          autoComplete="new-password"
          variant="filled"
          error={checkLength && password.length < 8}
          helperText={checkLength && password.length < 8 ? 'Password must be at least 8 characters' : 'Required*'}
        />
        <TextField
          data-node="confirm_password"
          onChange={e => updateConfirmPassword(e.target.value)}
          name="user[password_confirmation]"
          value={confirmPassword}
          className={classes.textField}
          id="user_confirm_password"
          label="Confirm New Password"
          type="password"
          fullWidth
          autoComplete="new-password"
          variant="filled"
          error={!!confirmPassword && confirmPassword !== password}
          helperText={!!confirmPassword && confirmPassword !== password ? 'Passwords must match' : 'Required*'}
        />
        {backToLogin && (
          <div className={classes.backToLogin}>
            <Button variant="link" href={Routes.new_user_session_path()} className={classes.linkBackToLogin}>
              Back to Login
            </Button>
          </div>
        )}
      </FormGroup>
      {submitButton && (
        <Button
          data-node="password_button"
          type="submit"
          className={classes.button}
          disabled={!password || !confirmPassword || password.length < 8 || password !== confirmPassword}
        >
          {buttonText}
        </Button>
      )}
    </>
  );
};

const PasswordFields = ({
  backToLogin = false,
  buttonText = 'Set Password',
  submitButton = true,
  className,
}: PasswordFieldsProps) => {
  const useNewLogin = useFeatureFlags('ff_new_login');
  return useNewLogin ? (
    <IUPasswordFields backToLogin={backToLogin} buttonText={buttonText} submitButton={submitButton} />
  ) : (
    <MUPasswordFields
      backToLogin={backToLogin}
      buttonText={buttonText}
      submitButton={submitButton}
      className={className}
    />
  );
};

export default PasswordFields;
