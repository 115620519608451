import React from 'react';
import { Button, Flex, Text, View, IconCheckDarkSolid } from '@instructure/ui';
import { redirectWithReloadPage } from 'utils/LocationHelpers';
import appRoutes from 'appRoutes';

interface CongratulationsProps {
  selectedCompany: Record<string, any>;
}

const Congratulations = ({ selectedCompany }: CongratulationsProps) => {
  return (
    <>
      <Flex direction="column">
        <Flex.Item shouldShrink shouldGrow>
          <View as="div" margin="large none none none" padding="large none none none" textAlign="center">
            <IconCheckDarkSolid size="medium" />
          </View>
          <View as="div" padding="large none none none" textAlign="center">
            <Text size="large">You have joined {selectedCompany.name}</Text>
            <br />
          </View>
        </Flex.Item>
        <Flex.Item shouldShrink shouldGrow>
          <View as="div" padding="large" textAlign="center">
            <Text>Please click below to access your Partner Portal</Text>
          </View>
        </Flex.Item>
        <Flex.Item shouldShrink shouldGrow>
          <View as="div" margin="none" width="100%">
            <Button
              data-node="get_started"
              color="primary"
              display="block"
              type="submit"
              onClick={() => {
                const path = appRoutes.organizationPath(selectedCompany.organizationId);
                return redirectWithReloadPage(path);
              }}
            >
              Get Started!
            </Button>
          </View>
        </Flex.Item>
      </Flex>
    </>
  );
};

export default Congratulations;
