import React, { useState, useMemo, useCallback } from 'react';
import { Text, View, Flex, Button } from '@instructure/ui';
import { Helmet } from 'react-helmet';
import { authenticityToken } from 'utils/FetchHelpers';
import IURoleRender from '../../../../sharedComponents/IURoleRender';

interface SelectRoleProps {
  onChangeRole: (role: string) => void;
  loginPath: string;
  registrationPath: string;
  disabled?: boolean;
  isMobile?: boolean;
  isTablet?: boolean;
}

const SelectRole: React.FC<SelectRoleProps> = ({
  isMobile,
  isTablet,
  onChangeRole,
  loginPath,
  registrationPath,
  disabled = false,
}) => {
  const [localRole, setLocalRole] = useState<string | null>(null);

  const nextStepLabel = useMemo(() => {
    return localRole === 'product_developer' ? 'Redirect to Partner Registration' : 'Next';
  }, [localRole]);

  const nextStepAllowed = useMemo(() => !!localRole, [localRole]);

  const padding = useMemo(() => (isMobile ? '5em' : '13em'), [isMobile]);

  const updatedRegistrationPath = useMemo(
    () => `${registrationPath}?primary_role=${localRole}&is_partner=true`,
    [localRole, registrationPath],
  );

  /* eslint lt-custom/inline-handlers: 0 */
  const handleButtonClick = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      if (localRole !== 'product_developer') {
        e.preventDefault();
        onChangeRole(localRole || '');
      }
    },
    [localRole, onChangeRole],
  );
  /* eslint lt-custom/inline-handlers: 1 */

  return (
    <>
      <Helmet>
        <title>Select Role</title>
      </Helmet>

      <Flex
        data-node="parent"
        as="div"
        direction="column"
        justifyItems="center"
        height={isTablet && !isMobile ? '100%' : `calc(100vh - ${padding})`}
      >
        <Flex.Item data-node="slr" shouldShrink shouldGrow overflowX="hidden" overflowY="hidden">
          <View as="div" minHeight="32em">
            <View as="div" margin="0 0">
              <Text weight="bold" size="x-large">
                Select Role
              </Text>
            </View>
            <View as="div" margin="large 0 large 0">
              <Text>Please select the role that best describes your responsibilities at your organization.</Text>
            </View>
            <IURoleRender
              selectedRole={localRole}
              onChangeRole={(role: string) => {
                setLocalRole(role);
                return role;
              }}
              disabled={disabled}
            />
          </View>
        </Flex.Item>
        <Flex.Item>
          <Flex as="div" margin="none" direction="row">
            <Flex.Item shouldShrink shouldGrow>
              <Button href={loginPath} color="secondary" margin="none none none xx-small" size="medium">
                Cancel
              </Button>
            </Flex.Item>
            <Flex.Item>
              <form action={updatedRegistrationPath} method="post" onSubmit={handleButtonClick}>
                <input type="hidden" name="authenticity_token" value={authenticityToken()} />
                <Button
                  type="submit"
                  data-node="submit_role"
                  interaction={!nextStepAllowed ? 'disabled' : 'enabled'}
                  color="primary"
                  margin="xx-small"
                  size="medium"
                >
                  {nextStepLabel}
                </Button>
              </form>
            </Flex.Item>
          </Flex>
        </Flex.Item>
      </Flex>
    </>
  );
};

export default SelectRole;
