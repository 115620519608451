import React from 'react';
import PropTypes from 'prop-types';
import { Button, Text, View } from '@instructure/ui';
import { useResponsiveContext } from 'hooks/useResponsiveContext';

const Role = ({ id, title, onSelect, selected, disabled = false }) => {
  const { isMobile } = useResponsiveContext();
  const buttonWidth = isMobile ? '6em' : '7em';
  return (
    <Button
      color={selected ? 'secondary' : 'primary'}
      onClick={() => {
        onSelect(id);
      }}
      data-node={`select_${id}_role`}
      disabled={disabled}
      margin="xx-small"
      type="buton"
      themeOverride={{
        primaryBackground: 'white',
        primaryHoverBackground: 'white',
        primaryColor: 'licorice',
        secondaryBackground: 'white',
        secondaryBorderColor: 'rgb(3,116,181)', // brand wasn't working here..
        borderWidth: selected ? '0.25rem' : '0.0625rem',
        borderRadius: '0.5rem',
      }}
    >
      <View as="div" width={buttonWidth} padding="small none small none">
        <Text size="medium" weight="bold">
          {title}
        </Text>
      </View>
    </Button>
  );
};

Role.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
};

export default Role;
