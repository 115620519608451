import React, { useEffect, useMemo, useState } from 'react';
import { isEmpty, join } from 'ramda';
import { useLocation } from 'react-use';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import cn from 'classnames';
import { View, Text, Button, Flex } from '@instructure/ui';
import Flash from 'components/Flash';
import Form from 'forms/MembershipForm';
import MembershipsRepository from 'repositories/CurrentUser/MembershipsRepository';
import { GENERIC_ERROR_MESSAGE } from 'constants/errorMessages';
import IUAutocompleteAsync from 'components/IUAutocompleteAsync';
import OrganizationRepository from 'repositories/OrganizationRepository';
import IUTextField from 'components/IUTextField';
import IUMultipleSelect from 'components/IUMultipleSelect/IUMultipleSelect';
import { makeTreeFromFlatList } from 'utils/Utils';
import TagGroupsRepository from 'repositories/TagGroupsRepository';
import useStyles from '../useStyles';
import IUMembershipTable from './components/IUMembershipTable';

interface IUMembershipSetupProps {
  errors: string[];
}

interface AllTagGroups {
  id: number;
  name: string;
  groupType: string;
  selectiontype: string;
  visible: boolean;
  tagInfo: TagGroup[];
}

interface TagGroup {
  id: number;
  name: string;
  children: TagGroup[];
  indentation: number;
}

const TYPES = [
  { value: 'School', label: 'School' },
  { value: 'SchoolDistrict', label: 'School District' },
  { value: 'StateEducationAgency', label: 'State Educational Agency' },
  { value: 'College', label: 'Higher Education' },
  { value: 'Group', label: 'Network' },
];

const IUMembershipSetup = ({ errors }: IUMembershipSetupProps) => {
  const classes = useStyles();
  const [error, setError] = useState(errors);
  const [form, setForm] = useState(Form.defaultAttributes());
  const [memberships, setMemberships] = useState(null);
  const [actableType, setActableType] = useState('School');
  const [loading, setLoading] = useState(false);
  const [allTagGroups, setTagGroups] = useState<AllTagGroups[]>([]);

  const { search } = useLocation();
  const parsedQueryString = queryString.parse(search || '');
  const redirect = parsedQueryString.redirect_back && parsedQueryString.redirect_back + location.hash;

  const gradeTags: TagGroup[] = useMemo(
    () => makeTreeFromFlatList(allTagGroups.find(tagGroup => tagGroup.name === 'Grade Level')?.tagInfo || []),
    [allTagGroups],
  );

  const subjectTags = useMemo(
    () => makeTreeFromFlatList(allTagGroups.find(tagGroup => tagGroup.name === 'Subject')?.tagInfo || []),
    [allTagGroups],
  );

  const getTags = () => {
    return TagGroupsRepository.index({ q: { name_matches_any: ['Grade Level', 'Subject'] } }).then(({ tagGroups }) => {
      setTagGroups(tagGroups);
    });
  };

  const getMemberships = async () => {
    return MembershipsRepository.index()
      .then(currentMemberships => {
        setMemberships(currentMemberships.memberships);
        setLoading(false);
      })
      .catch(err => {
        setError(err);
        throw err;
      });
  };

  useEffect(() => {
    setLoading(true);
    getTags();
    getMemberships();
  }, []);

  const handleChange = (field, e) => {
    setForm({ ...form, [field]: e.target.value });
  };

  return (
    <>
      <div id="flash-message" />
      <Helmet>
        <title>Configure Your Membership</title>
      </Helmet>
      <View as="div" margin="0 0">
        <Text weight="bold" size="x-large">
          Let’s finish setting up your profile
        </Text>
      </View>
      <View as="div" margin="large none none none">
        <Text>You can edit this information later in Manage Account under your Account icon.</Text>
      </View>

      {!isEmpty(error) && <Flash flash={['alert', join(', ', error)]} />}

      <View as="div" margin="large none" textAlign="center">
        <View as="div" margin="medium none">
          <Text weight="bold">Your Organization Memberships</Text>
        </View>
        <IUMembershipTable
          memberships={memberships}
          onDestroy={(membershipId: number) => {
            MembershipsRepository.destroy(membershipId).then(() => getMemberships());
          }}
          loading={loading}
        />
      </View>

      <View as="div" margin="medium none" textAlign="center">
        <Text weight="bold">Add Organization</Text>
      </View>

      <form
        onSubmit={async e => {
          e.preventDefault();
          const route = redirect || location.origin;
          if (isEmpty(form.organization)) {
            window.location.href = route;
            return;
          }
          MembershipsRepository.create(Form.attributesToSubmitWithTagIds(form))
            .then(() => {
              window.location.href = route;
            })
            .catch(err => {
              setError([GENERIC_ERROR_MESSAGE]);
              throw err;
            });
        }}
      >
        <View as="div">
          {TYPES.map(type => {
            return (
              <Button
                margin="small xx-small"
                color="secondary"
                key={type.value}
                onClick={() => setActableType(type.value)}
                className={cn(classes.radioButton, {
                  [classes.radioButtonActive]: actableType === type.value,
                })}
              >
                {type.label}
              </Button>
            );
          })}
        </View>

        <View as="div" margin="medium none">
          <Flex justifyItems="space-between" gap="medium">
            <Flex.Item shouldGrow>
              <IUAutocompleteAsync
                dataNode="select-organization"
                inputLabel="Your Organization"
                getOptions={(searchTerm: string) => {
                  const params = { page: 1, per_page: 20, q: { actable_type_eq: actableType, name_cont: searchTerm } };
                  return OrganizationRepository.index(params).then(({ organizations }) => [...organizations]);
                }}
                labelPath={['label']}
                onAdd={organization => {
                  setForm({ ...form, organization });
                }}
                value={form.organization?.name || ''}
                endIcon="chevron-down"
                fullWidthMenu
              />
            </Flex.Item>
            <Flex.Item shouldGrow>
              <IUTextField
                id="position"
                data-node="position"
                label="Position"
                value={form.role}
                fullWidth
                variant="filled"
                onChange={e => handleChange('role', e)}
                noMargin
              />
            </Flex.Item>
          </Flex>
        </View>
        <View as="div" margin="medium none">
          <IUMultipleSelect
            tags={gradeTags}
            value={form.grades}
            onChange={tags => {
              setForm({ ...form, grades: tags });
            }}
            label="Select Grade Levels"
          />
        </View>
        <View as="div" margin="medium none">
          <IUMultipleSelect
            tags={subjectTags}
            value={form.subjects}
            onChange={tags => {
              setForm({ ...form, subjects: tags });
            }}
            label="Select Subject Areas"
          />
        </View>

        <View as="div" textAlign="center">
          <Button data-node="submit_membership_button" color="primary" type="submit">
            {!isEmpty(form.organization) && 'Submit and '}Go to LearnPlatform
          </Button>
        </View>
      </form>
    </>
  );
};

export default IUMembershipSetup;
