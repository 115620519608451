import React from 'react';
import { Breadcrumb, View } from '@instructure/ui';

interface BreadcrumbItem {
  to?: string | undefined;
  onClick?: () => void;
  href?: string;
  label: React.ReactNode;
  node?: React.ReactNode;
}

interface IUBreadcrumbProps {
  items: BreadcrumbItem[];
  dataNode?: string;
}

const IUBreadcrumb: React.FC<IUBreadcrumbProps> = ({ items, dataNode }) => {
  return (
    <View as="div" padding="none none large none">
      <Breadcrumb label="You are here:" data-node={dataNode || 'breadcrumb'}>
        {items.map(item => {
          return (
            <Breadcrumb.Link key={item.label as string} href={item.href || item.to} onClick={item.onClick}>
              {item.node || item.label}
            </Breadcrumb.Link>
          );
        })}
      </Breadcrumb>
    </View>
  );
};

export default IUBreadcrumb;
