import { AccessibleContent, Alert, Select, Tag } from '@instructure/ui';
import React, { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

interface Option {
  id: number;
  name: string;
  indentation: number;
  children?: Option[];
}

interface IUMultipleSelectProps {
  tags: Option[];
  value: number[];
  onChange: (tags: number[]) => void;
  label: string;
}

const flattenOptions = (options: Option[] = []): Option[] => {
  return options.reduce((acc: Option[], option: Option) => {
    acc.push(option);
    if (option.children && option.children.length > 0) {
      acc.push(...flattenOptions(option.children));
    }
    return acc;
  }, []);
};

const IUMultipleSelect = ({ tags, value, onChange, label }: IUMultipleSelectProps) => {
  const [inputValue, setInputValue] = useState('');
  const [isShowingOptions, setIsShowingOptions] = useState(false);
  const [highlightedOptionId, setHighlightedOptionId] = useState<number | null>(null);
  const [selectedOptionId, setSelectedOptionId] = useState<number[]>(value || []);
  const [filteredOptions, setFilteredOptions] = useState(tags);
  const [announcement, setAnnouncement] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const flattenedOptions = useMemo(() => flattenOptions(tags), [tags]);

  useEffect(() => {
    const flat = flattenOptions(tags);
    setFilteredOptions(flat);
  }, [tags]);

  useEffect(() => {
    setSelectedOptionId(value);
  }, [value]);

  const getOptionById = useCallback(
    (queryId: number) => {
      return flattenedOptions.find(({ id }) => id === queryId);
    },
    [flattenedOptions],
  );

  const getOptionsChangedMessage = newOptions => {
    let message =
      newOptions.length !== filteredOptions.length
        ? `${newOptions.length} options available.` // options changed, announce new total
        : null; // options haven't changed, don't announce
    if (message && newOptions.length > 0) {
      // options still available
      if (highlightedOptionId !== newOptions[0].id) {
        // highlighted option hasn't been announced
        const option = getOptionById(newOptions[0].id);
        if (!option) {
          throw new Error(`Option with ID ${newOptions[0].id} not found.`);
        }
        message = `${option.name}. ${message}`;
      }
    }
    return message;
  };

  const filterOptions = (filterValue: string) => {
    if (!filterValue) return flattenedOptions;
    return flattenedOptions.filter(option => option.name.toLowerCase().startsWith(filterValue.toLowerCase()));
  };

  const matchValue = () => {
    // an option matching user input exists
    if (filteredOptions.length === 1) {
      const onlyOption = filteredOptions[0];
      // automatically select the matching option
      if (onlyOption.name.toLowerCase() === inputValue.toLowerCase()) {
        setInputValue('');
        setSelectedOptionId([...selectedOptionId, onlyOption.id]);
        setFilteredOptions(filterOptions(''));
      }
    }
    // input value is from highlighted option, not user input
    // clear input, reset options
    else if (highlightedOptionId) {
      if (inputValue === getOptionById(highlightedOptionId)?.name) {
        setInputValue('');
        setFilteredOptions(filterOptions(''));
      }
    }
  };

  const handleHighlightOption = (event: SyntheticEvent, data: { id?: string }) => {
    const intID = parseInt(data.id || '', 10); // convert id to number
    event.persist();
    const option = getOptionById(intID);
    if (!option) return; // prevent highlighting empty option
    setHighlightedOptionId(intID);
    setInputValue(event.type === 'keydown' ? option.name : inputValue);
    setAnnouncement(option.name);
  };

  const handleSelectOption = (event: SyntheticEvent, data: { id?: string }) => {
    const intID = parseInt(data.id || '', 10); // convert id to number
    const option = getOptionById(intID);
    if (!option) return; // prevent selecting of empty option
    const newSelection = [...selectedOptionId, intID];
    setSelectedOptionId(newSelection);
    onChange(newSelection);
    setHighlightedOptionId(null);
    setFilteredOptions(filterOptions(''));
    setInputValue('');
    setIsShowingOptions(false);
    setAnnouncement(`${option.name} selected. List collapsed.`);
  };

  const handleInputChange = event => {
    const { value: inputChangeValue } = event.target;
    const newOptions = filterOptions(inputChangeValue);
    setInputValue(inputChangeValue);
    setFilteredOptions(newOptions);
    setHighlightedOptionId(newOptions.length > 0 ? newOptions[0].id : null);
    setIsShowingOptions(true);
    setAnnouncement(getOptionsChangedMessage(newOptions));
  };

  // remove a selected option tag
  const dismissTag = (e, tag) => {
    // prevent closing of list
    e.stopPropagation();
    e.preventDefault();

    const newSelection = selectedOptionId.filter(id => id !== tag);

    setSelectedOptionId(newSelection);
    onChange(newSelection);
    setHighlightedOptionId(null);
    setAnnouncement(`${getOptionById(tag)?.name} removed`);

    inputRef.current?.focus();
  };

  const renderTags = () => {
    return selectedOptionId.map((id, index) => (
      <Tag
        dismissible
        key={id}
        text={
          <AccessibleContent alt={`Remove ${getOptionById(id)?.name}`}>{getOptionById(id)?.name}</AccessibleContent>
        }
        margin={index > 0 ? 'xxx-small xx-small xxx-small 0' : '0 xx-small 0 0'}
        onClick={e => dismissTag(e, id)}
      />
    ));
  };

  const renderOption = option => {
    if (selectedOptionId.indexOf(option.id) === -1) {
      return (
        <Select.Option id={String(option.id)} key={option.id} isHighlighted={option.id === highlightedOptionId}>
          <span style={{ paddingLeft: `${(option.indentation - 1) * 12}px` }}>{option.name}</span>
        </Select.Option>
      );
    }
    return null;
  };

  return (
    <div>
      <Select
        renderLabel={label}
        assistiveText="Type or use arrow keys to navigate options. Multiple selections allowed."
        inputValue={inputValue}
        isShowingOptions={isShowingOptions}
        onBlur={() => {
          setHighlightedOptionId(null);
        }}
        onInputChange={handleInputChange}
        onRequestShowOptions={() => {
          setIsShowingOptions(true);
        }}
        onRequestHideOptions={() => {
          setIsShowingOptions(false);
          matchValue();
        }}
        onRequestHighlightOption={handleHighlightOption}
        onRequestSelectOption={handleSelectOption}
        renderBeforeInput={selectedOptionId.length > 0 ? renderTags() : null}
      >
        {filteredOptions.length > 0 ? (
          filteredOptions.map(option => {
            return renderOption(option);
          })
        ) : (
          <Select.Option id="empty-option" key="empty-option">
            ---
          </Select.Option>
        )}
      </Select>
      <Alert
        liveRegion={() => document.getElementById('flash-message')!}
        liveRegionPoliteness="assertive"
        screenReaderOnly
      >
        {announcement}
      </Alert>
    </div>
  );
};

export default IUMultipleSelect;
