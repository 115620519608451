import { type Context, createContext } from 'react';

type InstUIBreakpoints = 'mobile' | 'tablet' | 'desktop';

export type ResponsiveQueries = Record<'mobile' | 'tablet', { maxWidth: number }> &
  Record<'desktop', { minWidth: number }>;

// Note: This provider doesn't provide multiple ranges.
// This could be updated to return multiple ranges in the future.

export const responsiveContextDefaultValues: ResponsiveQueries = {
  mobile: {
    maxWidth: 767,
  },
  tablet: {
    maxWidth: 1023,
  },
  desktop: {
    minWidth: 1024,
  },
};

export type QueriesMatching = (InstUIBreakpoints | undefined)[];

export type ResponsiveContextType = {
  query: ResponsiveQueries;
  matches: QueriesMatching;
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
};

// Create a context with some default values
export const ResponsiveContext: Context<ResponsiveContextType> = createContext<ResponsiveContextType>({
  query: responsiveContextDefaultValues,
  matches: [],
  isDesktop: false,
  isTablet: false,
  isMobile: false,
});
