import React from 'react';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import IUClaimCompany from './IUClaimCompany';
import MUClaimCompany from './MUClaimCompany';

interface ClaimCompanyProps {
  loadCompanies: () => void;
  joinToCompany: () => void;
  createCompany: () => void;
  compareEmailWithCompanyLink: () => void;
  loading?: boolean;
  errors: object;
}

const ClaimCompany = ({ ...rest }: ClaimCompanyProps) => {
  const useNewLogin = useFeatureFlags('ff_new_login');
  const { isPartner } = gon;
  if (useNewLogin || isPartner) return <IUClaimCompany {...rest} />;
  return <MUClaimCompany {...rest} />;
};

export default ClaimCompany;
