import { combineReducers } from 'redux';
import ClaimCompanyReducer from 'containers/PreApplication/ClaimCompany/MUClaimCompany/ClaimCompanyReducer';
import NotificationsReducer from 'sharedContainers/Notifications/NotificationsReducer';

const preApplicationReducers = combineReducers({
  ClaimCompanyReducer,
  NotificationsReducer,
});

export default preApplicationReducers;
