import React from 'react';
import { isNil } from 'ramda';
import { Button, View } from '@instructure/ui';
import TextField from 'components/IUTextField';
import { isBlank } from 'utils/Utils';
import { URL_DEFAULT_PROTOCOL_STRING } from 'presenters/OrganizationTool/ResourcePresenter';
import IUPanelFrame from '../../../../sharedComponents/IUPanelFrame';

interface CreateCompanyFormProps {
  onSubmitForm: () => void;
  form: Record<string, any>;
  onGoBack: () => void;
  padding?: string;
  errors: Record<string, any>;
  onChangeForm: (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CreateCompanyForm = ({
  onSubmitForm,
  form: { name, url },
  onGoBack,
  errors,
  onChangeForm,
  padding,
}: CreateCompanyFormProps) => {
  return (
    <>
      <IUPanelFrame
        padding={padding}
        cancelButton={
          <View data-node="button-wrapper" as="div" padding="xx-small">
            <Button onClick={onGoBack}>Go Back</Button>
          </View>
        }
        nextButton={
          <View data-node="button-wrapper" as="div" padding="xx-small">
            <Button data-node="claim_button" size="medium" color="primary" type="submit" onClick={onSubmitForm}>
              Create Your Company
            </Button>
          </View>
        }
      >
        <>
          <TextField
            id="company-name"
            label="Company Name"
            type="text"
            name="company-name"
            fullWidth
            onChange={onChangeForm('name')}
            value={name}
            error={!(isBlank(errors.companyName) && isBlank(errors.name))}
            helperText={isBlank(errors.name) ? 'Required Field *' : errors.name}
          />
          <TextField
            id="company-url"
            label="Company URL"
            placeholder={URL_DEFAULT_PROTOCOL_STRING}
            type="text"
            name="company-url"
            variant="primary"
            fullWidth
            onChange={onChangeForm('url')}
            value={url}
            error={!isNil(errors.url)}
            helperText={isNil(errors.url) ? 'Required Field *' : errors.url}
          />
        </>
      </IUPanelFrame>
    </>
  );
};

export default CreateCompanyForm;
