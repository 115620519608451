import React, { useEffect, useState, ChangeEvent } from 'react';
import { Helmet } from 'react-helmet';
import { map, pathOr } from 'ramda';

import {
  IconAdminLine,
  IconEyeLine,
  Flex,
  Text,
  Button,
  IconButton,
  View,
  FormFieldGroup,
  Link,
} from '@instructure/ui';
import * as Routes from 'Routes';
import { authenticityToken } from 'utils/FetchHelpers';
import Flash from 'components/IUFlash';
import IUSocialAuthButtons from 'components/IUSocialAuthButtons';
import TextField from 'components/IUTextField';

interface IUSignInProps {
  user: {
    email?: string;
  };
  isPartner?: boolean;
}

const IUSignIn = ({ user, isPartner }: IUSignInProps) => {
  const [email, setEmail] = useState<string>(pathOr('', ['email'], user));
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<any[]>(gon.flash);

  const handleChange = (field: 'email' | 'password') => (e: ChangeEvent<HTMLInputElement>) => {
    if (field === 'email') setEmail(e.target.value);
    if (field === 'password') setPassword(e.target.value);
  };

  useEffect(() => {
    setNotifications(gon.flash);
  }, [gon.flash]);

  const appName = isPartner ? 'the Partner Portal' : 'LearnPlatform';
  const appHeader = isPartner ? 'Partner Portal' : 'LearnPlatform';

  const renderFlash = (flash: [string, string]) => <Flash key={flash[1]} flash={flash} />;
  return (
    <>
      <Helmet>
        <title>{appHeader}</title>
      </Helmet>
      <Text weight="bold" size="xx-large">
        Welcome to {appName}
      </Text>
      <View padding="xx-large none none none" as="div">
        {map(renderFlash, notifications)}
        <form action={Routes.new_user_session_path()} method="post">
          <FormFieldGroup description="" layout="stacked" rowSpacing="medium">
            <input type="hidden" name="authenticity_token" value={authenticityToken()} />
            <TextField
              data-node="user_email_field"
              name="user[email]"
              onChange={handleChange('email')}
              value={email}
              placeholder=""
              autoComplete="username"
              id="email"
              label="Email Address"
              variant="filled"
              helperText="Required*"
              fullWidth
            />
            <TextField
              data-node="user_password_field"
              placeholder=""
              name="user[password]"
              type={showPassword ? 'text' : 'password'}
              onChange={handleChange('password')}
              value={password}
              autoComplete="current-password"
              id="password"
              label="Password"
              variant="filled"
              helperText="Required*"
              renderAfterInput={
                <IconButton
                  /* @ts-ignore */
                  onClick={() => setShowPassword(!showPassword)}
                  shape="circle"
                  size="small"
                  withBorder={false}
                  withBackground={false}
                  screenReaderLabel="Show password"
                  renderIcon={showPassword ? IconAdminLine : IconEyeLine}
                  data-node="login-show-password-button"
                />
              }
              fullWidth
            />
            <View as="div" margin="large xx-small xx-small xx-small">
              <Button
                type="submit"
                display="block"
                color="primary"
                textAlign="center"
                interaction="enabled"
                data-node="login-submit-button"
              >
                Log In
              </Button>
            </View>

            <Flex alignItems="center" justifyItems="center">
              <Link
                href={Routes.new_user_password_path()}
                isWithinText={false}
                data-node="login-forgot-password-button"
              >
                Forgot Password?
              </Link>
            </Flex>
          </FormFieldGroup>
        </form>
      </View>
      <View as="div" margin="medium xx-small xx-small xx-small">
        <IUSocialAuthButtons />
      </View>
    </>
  );
};

interface IUSignInProps {
  user: {
    email?: string;
  };
  isPartner?: boolean;
}

export default IUSignIn;
