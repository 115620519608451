import React from 'react';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import IUResendConfirmation from './IUResendConfirmation';
import MUResendConfirmation from './MUResendConfirmation';

interface ResendConfirmationProps {
  errors: string[];
}

const ResendConfirmation = ({ errors }: ResendConfirmationProps) => {
  const useNewLogin = useFeatureFlags('ff_new_login');
  const { isPartner } = gon;
  if (useNewLogin || isPartner) return <IUResendConfirmation errors={errors} />;
  return <MUResendConfirmation errors={errors} />;
};

export default ResendConfirmation;
