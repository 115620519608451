import React from 'react';
import { TextInput, TextInputProps, View } from '@instructure/ui';

type Props = Partial<TextInputProps> & {
  fullWidth?: boolean;
  autoComplete?: string;
  variant?: string;
  label?: string;
  name?: string;
  error?: boolean;
  helperText?: string;
  maxLength?: number;
  noMargin?: boolean;
};

const TextField: React.FC<Props> = ({
  maxLength = 0,
  helperText = '',
  value = '',
  onChange,
  error = false,
  fullWidth = false,
  label = '',
  autoComplete = 'off',
  noMargin,
  ...rest
}) => {
  const showMaxLength = maxLength > 0;

  return (
    <View as="div" margin={!noMargin ? 'medium xx-small xx-small xx-small' : undefined}>
      <TextInput
        autoComplete={autoComplete}
        renderLabel={label}
        display={fullWidth ? 'block' : 'inline-block'}
        value={value}
        renderAfterInput={showMaxLength ? <>{`${value.length}/${maxLength}`}</> : undefined}
        onChange={
          onChange &&
          (e => {
            if (showMaxLength && e.target.value.length > maxLength) {
              e.target.value = e.target.value.substring(0, maxLength);
            }
            return onChange(e, e.target.value);
          })
        }
        messages={error ? [{ text: helperText, type: 'error' }] : []}
        {...rest}
      />
    </View>
  );
};

export default TextField;
