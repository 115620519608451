import React, { useCallback, useState } from 'react';
import { isEmpty } from 'ramda';
import { Button, FormFieldGroup, View } from '@instructure/ui';
import AutocompleteAsync from 'components/IUAutocompleteAsync';
import Form from 'forms/ClaimCompany/JoinToCompanyForm';
import { set, isBlank } from 'utils/Utils';
import IUPanelFrame from '../../../../sharedComponents/IUPanelFrame';
import { Company } from '../../Company';

interface JoinToCompanyFormProps {
  selectedCompany: Company;
  onLoadCompanies: (searchTerm: string) => Promise<any>;
  onSubmitForm: (form: any) => void;
  onChangeSelectedCompany: (company: { id: string; name: string }) => void;
  onCantFindCompany: () => void;
  padding?: string;
}

const JoinToCompanyForm: React.FC<JoinToCompanyFormProps> = ({
  selectedCompany,
  onLoadCompanies,
  onSubmitForm,
  onChangeSelectedCompany,
  onCantFindCompany,
  padding,
}) => {
  const [form, setForm] = useState(Form.defaultAttributes({ companyId: selectedCompany.id }));
  const [errors, setErrors] = useState<Record<string, any>>({});

  const handleSubmitForm = useCallback(() => {
    const validationErrors = Form.validate(form);

    if (!isBlank(validationErrors)) {
      setErrors(validationErrors);
      return;
    }

    setErrors({});
    onSubmitForm(form);
  }, [form, onSubmitForm]);

  return (
    <>
      <IUPanelFrame
        padding={padding}
        cancelButton={
          <View data-node="button-wrapper" as="div" padding="xx-small">
            <Button data-node="link_back_to_find" onClick={onCantFindCompany}>
              I Cannot Find My Company
            </Button>
          </View>
        }
        nextButton={
          <View data-node="button-wrapper" as="div" padding="xx-small">
            <Button
              data-node="claim_button"
              size="medium"
              color="primary"
              type="submit"
              onClick={handleSubmitForm}
              interaction={isEmpty(selectedCompany) ? 'disabled' : 'enabled'}
            >
              Join Company
            </Button>
          </View>
        }
      >
        <FormFieldGroup description="">
          <AutocompleteAsync
            getOptions={onLoadCompanies}
            placeholder="Search for your company"
            dataNode="company_field"
            onAdd={(company: { id: string; name: string }) => {
              setForm(set(form, ['companyId'], company.id));
              onChangeSelectedCompany(company);
            }}
            startIcon="IconSearchSolid"
            fullWidthMenu
            value={selectedCompany.name}
            helperText={errors.companyId && errors.companyId}
            error={!!errors.companyId}
          />
        </FormFieldGroup>
      </IUPanelFrame>
    </>
  );
};

export default JoinToCompanyForm;
