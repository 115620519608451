import React from 'react';
import { pathOr } from 'ramda';
import { Button, Text, View } from '@instructure/ui';
import appRoutes from 'appRoutes';

interface NotificationProps {
  fsm: string;
  fsmStates: Record<string, any>;
  selectedCompany: {
    name?: string;
  };
}

const Notification = ({ fsm, fsmStates, selectedCompany }: NotificationProps) => {
  const name = pathOr('', ['name'], selectedCompany);

  if (fsm === fsmStates.requestingAccess) {
    return (
      <>
        <View as="div">
          <View as="div" padding="medium none">
            <Text weight="light">
              Access for <span>{name}</span> has been requested from the company!
            </Text>
          </View>
          <View as="div">
            <Text weight="light">Check your email for further information.</Text>
          </View>
        </View>
        <View as="div" padding="x-large none">
          <Button data-node="got_it_button" size="medium" color="primary" href={appRoutes.learnCommunityPath()}>
            Got it!
          </Button>
        </View>
      </>
    );
  }

  if (fsm === fsmStates.confirmCompany) {
    return (
      <>
        <View as="div" margin="none none large none">
          <Text weight="bold" size="x-large">
            Select your Company
          </Text>
        </View>
      </>
    );
  }

  if (fsm === fsmStates.createCompany) {
    return (
      <View as="div" margin="none none large none">
        <Text weight="bold" size="x-large">
          Create your Company
        </Text>
      </View>
    );
  }

  return (
    <>
      <View as="div" margin="none none medium none">
        <Text weight="bold" size="x-large">
          Select Your Company
        </Text>
      </View>
      <View as="div" margin="none none medium none">
        <Text>Select the company you wish to request access to.</Text>
      </View>
    </>
  );
};

export default Notification;
