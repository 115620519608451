import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-use';
import { isEmpty, join } from 'ramda';
import queryString from 'query-string';
import cn from 'classnames';
import { makeTreeFromFlatList } from 'utils/Utils';

import Form from 'forms/MembershipForm';

import AutocompleteAsync from 'components/AutocompleteAsync';
import Button from 'components/Button';
import Chip from 'components/Chip';
import Icon from 'components/Icon';
import Flash from 'components/Flash';
import TagMenu from 'components/Tools/Filters/TagMenu';
import TextField from 'components/TextField';

import MembershipsRepository from 'repositories/CurrentUser/MembershipsRepository';
import OrganizationRepository from 'repositories/OrganizationRepository';
import TagGroupsRepository from 'repositories/TagGroupsRepository';

import { GENERIC_ERROR_MESSAGE } from 'constants/errorMessages';
import MembershipTable from '../components/MembershipTable';

import useStyles from '../useStyles';

const TYPES = [
  { value: 'School', label: 'School' },
  { value: 'SchoolDistrict', label: 'School District' },
  { value: 'StateEducationAgency', label: 'State Educational Agency' },
  { value: 'College', label: 'Higher Education' },
  { value: 'Group', label: 'Network' },
];

const MUMembershipSetup = ({ errors }) => {
  const classes = useStyles();
  const [form, updateForm] = useState(Form.defaultAttributes());
  const [memberships, updateMemberships] = useState(null);
  const [actableType, setActableType] = useState('School');
  const [loading, updateLoading] = useState(false);
  const [allTagGroups, updateTagGroups] = useState([]);
  const [error, updateError] = useState(errors);

  const location = useLocation();
  const parsedQueryString = queryString.parse(location.search);
  const redirect = parsedQueryString.redirect_back && parsedQueryString.redirect_back + location.hash;

  const flatTags = useMemo(
    () => allTagGroups.reduce((acc, tagGroup) => [...acc, ...tagGroup.tagInfo], []),
    [allTagGroups],
  );
  const gradeTags = useMemo(
    () => makeTreeFromFlatList(allTagGroups.find(tagGroup => tagGroup.name === 'Grade Level')?.tagInfo || []),
    [allTagGroups],
  );
  const subjectTags = useMemo(
    () => makeTreeFromFlatList(allTagGroups.find(tagGroup => tagGroup.name === 'Subject')?.tagInfo || []),
    [allTagGroups],
  );

  const getTags = () => {
    return TagGroupsRepository.index({ q: { name_matches_any: ['Grade Level', 'Subject'] } }).then(({ tagGroups }) => {
      updateTagGroups(tagGroups);
    });
  };

  const getMemberships = () => {
    return MembershipsRepository.index()
      .then(currentMemberships => {
        updateMemberships(currentMemberships.memberships);
        updateLoading(false);
      })
      .catch(err => {
        updateError([GENERIC_ERROR_MESSAGE]);
        throw err;
      });
  };

  useEffect(() => {
    updateLoading(true);
    getTags();
    getMemberships();
  }, []);

  useEffect(() => {
    if (errors !== error) {
      updateError(errors);
    }
  }, [errors]);

  const handleChange = (field, e) => {
    updateForm({ ...form, [field]: e.target.value });
  };

  const handleSelectChange = (field, tagIds) => {
    updateForm({ ...form, [field]: tagIds });
  };

  const handleDeleteTag = (field, tagId) => {
    updateForm({ ...form, [field]: form[field].filter(id => id !== tagId) });
  };

  const submitRequest = e => {
    e.preventDefault();
    const route = redirect || location.origin;

    if (isEmpty(form.organization)) {
      window.location = route;
      return;
    }
    MembershipsRepository.create(Form.attributesToSubmitWithTagIds(form))
      .then(() => {
        window.location = route;
      })
      .catch(err => {
        updateError([GENERIC_ERROR_MESSAGE]);
        throw err;
      });
  };

  return (
    <>
      <Helmet>
        <title>Configure Your Membership</title>
      </Helmet>
      <div className={classes.root}>
        <Icon icon="welcome" />
        <div className={classes.title}>Let’s finish setting up your profile!</div>
        <div className={classes.description}>
          You can edit this information later in Manage Account under your Account icon.
        </div>
        {!isEmpty(error) && <Flash flash={['alert', join(', ', error)]} />}

        <div className={classes.memberships}>Your Organization Memberships</div>
        <MembershipTable
          memberships={memberships}
          onDestroy={membershipId => {
            MembershipsRepository.destroy(membershipId).then(() => {
              getMemberships();
            });
          }}
          loading={loading}
        />

        <div className={classes.memberships}>Add Organization</div>
        <form onSubmit={submitRequest} className={classes.form}>
          <div className={classes.radioButtonContainer}>
            {TYPES.map(type => {
              return (
                <Button
                  color="bordered"
                  key={type.value}
                  onClick={() => setActableType(type.value)}
                  className={cn(classes.radioButton, {
                    [classes.radioButtonActive]: actableType === type.value,
                  })}
                >
                  {type.label}
                </Button>
              );
            })}
          </div>
          <div className={classes.textFieldContainer}>
            <AutocompleteAsync
              dataNode="select-organization"
              inputLabel="Your Organization"
              getOptions={(searchTerm, page = 1) => {
                const params = {
                  page,
                  per_page: 20,
                  q: {
                    actable_type_eq: actableType,
                    name_cont: searchTerm,
                  },
                  ...(actableType === 'Group' && { without_organization_memberships: true }),
                };
                return OrganizationRepository.index(params).then(({ meta, organizations }) => ({
                  meta,
                  options: organizations,
                }));
              }}
              hasInfiniteScroll
              labelPath={['label']}
              onAdd={organization => {
                updateForm({ ...form, organization });
              }}
              value={form.organization?.name || ''}
              className={cn(classes.textField, classes.autocomplete, {
                [classes.autocompleteFilled]: !isEmpty(form.organization),
              })}
              endIcon="chevron-down"
              endIconClassName={classes.autocompleteIcon}
              saveValue
              menuAbove
            />
            <TextField
              id="position"
              data-node="position"
              label="Position"
              value={form.role}
              fullWidth
              variant="filled"
              onChange={e => handleChange('role', e)}
              className={classes.textField}
            />
          </div>
          <div className={classes.tagSelectContainer}>
            <div className={classes.tagContainer}>
              <TagMenu
                tags={gradeTags}
                value={form.grades}
                label="-Select Grade Levels-"
                placeholderLabel="Grade Levels"
                onChange={e => handleSelectChange('grades', e)}
                onDelete={e => handleDeleteTag('grades', e)}
                className={classes.tagSelect}
              />
              {form.grades.map(gradeTagId => {
                const gradeTag = flatTags.find(item => item.id === gradeTagId);
                return (
                  <Chip
                    onDelete={() => handleDeleteTag('grades', gradeTagId)}
                    label={gradeTag.name}
                    className={classes.chip}
                  />
                );
              })}
            </div>
            <div className={classes.tagContainer}>
              <TagMenu
                tags={subjectTags}
                value={form.subjects}
                label="-Select Subject Areas-"
                placeholderLabel="Subject Areas"
                onChange={e => handleSelectChange('subjects', e)}
                onDelete={e => handleDeleteTag('subjects', e)}
                className={classes.tagSelect}
              />
              {form.subjects.map(subjectTagId => {
                const subjectTag = flatTags.find(item => item.id === subjectTagId);
                return (
                  <Chip
                    onDelete={() => handleDeleteTag('subjects', subjectTagId)}
                    label={subjectTag.name}
                    className={classes.chip}
                  />
                );
              })}
            </div>
          </div>
          <Button data-node="submit_membership_button" type="submit" className={classes.button}>
            {!isEmpty(form.organization) && 'Submit and '}Go to LearnPlatform
          </Button>
        </form>
      </div>
    </>
  );
};

MUMembershipSetup.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
};

export default MUMembershipSetup;
