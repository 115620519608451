import React from 'react';
import { Spinner, SpinnerProps, View } from '@instructure/ui';

interface LoaderProps extends SpinnerProps {
  size?: SpinnerProps['size'];
}

const Loader = ({ size = 'large', ...rest }: LoaderProps) => {
  return (
    <View as="div" data-node="loader" textAlign="center" padding="large">
      <Spinner renderTitle={() => 'Loading'} size={size} {...rest} />
    </View>
  );
};

export default Loader;
