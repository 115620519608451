import React from 'react';
import useFeatureFlags from 'hooks/useFeatureFlags';
import IUMembershipSetup from './IUMembershipSetup/IUMembershipSetup';
import MUMembershipSetup from './MUMembershipSetup/MUMembershipSetup';

interface MembershipSetupProps {
  errors: string[];
}
const MembershipSetup = ({ errors }: MembershipSetupProps) => {
  const useNewLogin = useFeatureFlags('ff_new_login');
  if (useNewLogin) return <IUMembershipSetup errors={errors} />;
  return <MUMembershipSetup errors={errors} />;
};

export default MembershipSetup;
