import React from 'react';
import { Table, View, Text } from '@instructure/ui';
import Icon from 'components/Icon';

interface IUNoDataProps {
  type: string;
  title: string;
}
const IUNoData = ({ type, title }: IUNoDataProps) => {
  if (type === 'table') {
    return (
      <Table.Row>
        <Table.Cell data-node="no_data_text">
          <View as="div" textAlign="center">
            {title}
          </View>
        </Table.Cell>
      </Table.Row>
    );
  }
  if (type === 'chart') {
    return (
      <View as="div" textAlign="center" margin="medium">
        <View as="div" margin="medium">
          <Icon icon="chartBarSolid" />
        </View>
        <Text size="large">{title}</Text>
      </View>
    );
  }
  return (
    <View as="div" textAlign="center" margin="medium">
      <Text size="large">{title}</Text>
    </View>
  );
};

export default IUNoData;
