import { connect } from 'react-redux';
import React, { useState, ChangeEvent } from 'react';
import { Helmet } from 'react-helmet';
import CreateCompanyForm from 'forms/ClaimCompany/CreateNewCompanyForm';
import JoinToCompanyForm from 'forms/ClaimCompany/JoinToCompanyForm';
import Loader from 'components/Loader';
import { isBlank } from 'utils/Utils';
import { useResponsiveContext } from 'hooks/useResponsiveContext';
import {
  loadCompanies as loadCompaniesAction,
  joinToCompany as joinToCompanyAction,
  createCompany as createCompanyAction,
  compareEmailWithCompanyLink as compareEmailWithCompanyLinkAction,
} from './ClaimCompanyActions';
import Congratulations from './components/Congratulations';
import JoinToCompanyFormComponent from './components/JoinToCompanyForm';
import CreateCompanyFormComponent from './components/CreateCompanyForm';
import ConfirmCompany from './components/ConfirmCompany';
import Notification from './components/Notification';
import { Company } from './Company';

export const FSM_STATES = {
  selectCompany: 'selectCompany',
  createCompany: 'createCompany',
  requestingAccess: 'requestingAccess',
  congratulation: 'congratulation',
  confirmCompany: 'confirmCompany',
};

interface ClaimCompanyProps {
  loadCompanies: (params: any) => Promise<any>;
  joinToCompany: (organizationId: string) => Promise<void>;
  createCompany: (params: any) => Promise<any>;
  compareEmailWithCompanyLink: (params: any) => Promise<void>;
  loading?: boolean;
  errors: Record<string, any>;
}

const IUClaimCompany = ({
  loadCompanies,
  joinToCompany,
  createCompany,
  compareEmailWithCompanyLink,
  loading = false,
  errors,
}: ClaimCompanyProps) => {
  const [FSM, setFSM] = useState(FSM_STATES.selectCompany);
  const [selectedCompany, setSelectedCompany] = useState<Company>({});
  const [form, setForm] = useState(CreateCompanyForm.defaultAttributes());
  const [formErrors, setFormErrors] = useState<Record<string, any>>({});
  const { isMobile, isDesktop } = useResponsiveContext();
  const padding = isMobile || isDesktop ? '16em' : '40em';

  const handleChangeForm = (field: string) => (event: ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [field]: event.target.value });
  };

  const handleSubmitForm = () => {
    const validationErrors = CreateCompanyForm.validate(form);

    if (!isBlank(validationErrors)) {
      return setFormErrors(validationErrors);
    }

    setFormErrors({});
    return handleSubmitCreateNewCompanyForm(form);
  };

  const loadCompaniesList = (searchTerm: string, page = 1) => {
    const params = { page, per_page: 20, q: { search_terms_cont: searchTerm, sorts: 'id' } };
    return loadCompanies(params).then(response => response.companies);
  };

  const handleSubmitCreateNewCompanyForm = (localForm: Record<string, any>) => {
    const params = CreateCompanyForm.attributesToSubmit(localForm);
    return createCompany(params)
      .then(company => {
        joinToCompany(company.organizationId).then(() => {
          setFSM(FSM_STATES.congratulation);
          setSelectedCompany(company);
        });
      })
      .catch(() => {
        setFSM(FSM_STATES.createCompany);
      });
  };

  const handleSubmitJoinToCompanyForm = (localForm: Record<string, any>) => {
    const params = JoinToCompanyForm.attributesToSubmit(localForm);
    const { organizationId } = selectedCompany;
    return compareEmailWithCompanyLink(params)
      .then(() => {
        joinToCompany(organizationId || '').then(() => {
          setFSM(FSM_STATES.congratulation);
        });
      })
      .catch(() => {
        setFSM(FSM_STATES.confirmCompany);
      });
  };

  const handleConfirmCompany = () => {
    const { organizationId } = selectedCompany;
    return joinToCompany(organizationId || '').then(() => {
      setFSM(FSM_STATES.requestingAccess);
    });
  };

  const handleCantFindCompany = () => {
    setFSM(FSM_STATES.createCompany);
  };

  const handleChangeSelectedCompany = (company: Record<string, any>) => {
    setSelectedCompany(company);
  };

  const handleGoBack = () => {
    setFSM(FSM_STATES.selectCompany);
  };

  const renderJoinToCompanyForm = () => (
    <JoinToCompanyFormComponent
      selectedCompany={selectedCompany}
      onLoadCompanies={loadCompaniesList}
      onSubmitForm={handleSubmitJoinToCompanyForm}
      onChangeSelectedCompany={handleChangeSelectedCompany}
      onCantFindCompany={handleCantFindCompany}
      padding={padding}
    />
  );

  const renderConfirmCompanyForm = () => (
    <ConfirmCompany
      padding={padding}
      selectedCompany={selectedCompany}
      onSubmitForm={handleConfirmCompany}
      onGoBack={handleGoBack}
    />
  );

  const renderTextNotification = () => (
    <Notification fsm={FSM} fsmStates={FSM_STATES} selectedCompany={selectedCompany} />
  );

  const renderCreateCompanyForm = () => {
    const combinedErrors = { ...formErrors, ...errors };

    return (
      <CreateCompanyFormComponent
        padding={padding}
        onGoBack={handleGoBack}
        onSubmitForm={handleSubmitForm}
        onChangeForm={handleChangeForm}
        form={form}
        errors={combinedErrors}
      />
    );
  };

  const renderData = () => {
    if (FSM === FSM_STATES.congratulation) {
      return <Congratulations selectedCompany={selectedCompany} />;
    }

    return (
      <>
        {renderTextNotification()}
        {FSM === FSM_STATES.selectCompany && renderJoinToCompanyForm()}
        {FSM === FSM_STATES.createCompany && renderCreateCompanyForm()}
        {FSM === FSM_STATES.confirmCompany && renderConfirmCompanyForm()}
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Partner Sign-up</title>
      </Helmet>
      {loading ? <Loader /> : renderData()}
    </>
  );
};

const mapStateToProps = (state: any, ownProps: any) => ({
  loading: state.ClaimCompanyReducer.loading,
  errors: state.ClaimCompanyReducer.errors,
  ...ownProps,
});

export default connect(mapStateToProps, {
  joinToCompany: joinToCompanyAction,
  loadCompanies: loadCompaniesAction,
  createCompany: createCompanyAction,
  compareEmailWithCompanyLink: compareEmailWithCompanyLinkAction,
})(IUClaimCompany);
